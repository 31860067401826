import config from 'app/config'
import { useStores } from 'app/store'
import makeSiteGraphqlRequest from 'app/lib/graphql-request'
import { useState } from 'react'
import { Mutation } from 'app/types/mutation'
import { logError } from 'app/lib/log-error'

export default function useMutation(
  query: string,
  formatFn: (data: any) => Mutation
) {
  const { userStore } = useStores()
  const [data, setData] = useState<Mutation | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  async function makeRequest(variables: any) {
    if (!userStore) return
    setLoading(true)

    try {
      const data: Mutation = await makeSiteGraphqlRequest<Mutation>(
        config,
        query,
        variables,
        {
          userStore,
          formatFn,
        }
      )

      if (data?.success) {
        setData(data)
        setError(null)
      } else {
        setError(new Error(data.reason))
      }

      return data
    } catch (e) {
      console.error(e)
      logError(e, query, variables)
    } finally {
      setLoading(false)
    }
  }

  return { data, loading, error, makeRequest }
}
