import { XStack, XStackProps } from '@mythical/ui'
import React from 'react'

interface PaginationDotsProps {
  count: number
  activeIndex: number
  onChange: (index: number) => void
}

export const PaginationDots = (props: PaginationDotsProps & XStackProps) => {
  const { count, activeIndex, onChange, ...wrapperProps } = props
  return (
    <XStack justifyContent="center" gap="$2" flexWrap="wrap" {...wrapperProps}>
      {Array(count)
        .fill({})
        .map((_, i) => {
          const isActive = activeIndex === i
          return (
            <XStack
              tag="button"
              key={i}
              w={8}
              h={8}
              borderRadius={1000}
              backgroundColor={isActive ? '$primary1' : '$secondary8'}
              onPress={() => onChange(i)}
              cursor="pointer"
              aria-label={`Go to slide ${i + 1}`}
              pressStyle={{
                opacity: 0.7,
              }}
              $gtSm={{
                w: 11,
                h: 11
              }}
            />
          )
        })}
    </XStack>
  )
}
