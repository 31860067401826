import { styled, YStack } from 'tamagui'

export const Container = styled(YStack, {
  w: '100%',
  variants: {
    centered: {
      true: {
        mx: 'auto',
      },
    },
    smaller: {
      true: {
        maxWidth: 500,
      },
    },
    small: {
      true: {
        maxWidth: 640,
      },
    },
    medium: {
      true: {
        maxWidth: 768,
      },
    },
    mediumLarge: {
      true: {
        maxWidth: 960,
      },
    },
    large: {
      true: {
        maxWidth: 1024,
      },
    },
  } as const,
  defaultVariants: {
    medium: true,
    centered: true,
  },
})
