import React from 'react'
import { SizableText, SizableTextProps, styled } from '@mythical/ui'

type QnAQuestionHeadingProps = {
  question: string
}

const ResponsiveHeading = styled(SizableText, {
  tag: 'h3',
  ff: '$heading',
  color: '$primary1',
  tt: 'uppercase',
  ta: 'center',
  variants: {
    variant: {
      sm: {
        size: '$5',
        fow: '500',
        $gtXxxs: { size: '$6', fow: '500' },
        $gtXs: { size: '$7', fow: '500' },
        $gtMd: { size: '$8', fow: '500' },
      },
      md: {
        size: '$6.5',
        fow: '500',
        $gtXxxs: { size: '$7', fow: '500' },
        $gtMd: { size: '$8', fow: '500' },
      },
      xl: {
        size: '$7',
        fow: '500',
        $gtXxxs: { size: '$8', fow: '500' },
      },
    },
  } as const,
})

const getFontVariant = (question: string) => {
  const length = question.length
  if (length > 100) {
    return 'sm'
  }
  if (length > 60) {
    return 'md'
  }
  return 'xl'
}

export const QnAQuestionHeading = ({
  question,
  ...props
}: QnAQuestionHeadingProps & SizableTextProps) => {
  const variant = getFontVariant(question)
  return (
    <ResponsiveHeading variant={variant} {...props}>
      {question}
    </ResponsiveHeading>
  )
}
