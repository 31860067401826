import { isWeb } from '@mythical/ui'
import { UserStore } from 'app/store/user-store'

// @ts-ignore they broke their types
import type { PlaylistItem } from '@jwplayer/jwplayer-react-native'
import * as Device from 'expo-device'
import { isAndroid, isIOS } from 'app/utils/constants'

export interface VideoProps {
  id: string
  url: string
  hevcVideo: string
  title?: string
  thumbnail?: string
  player: string
  captions?: string
  data?: { playlist?: any[] }
  isUnlocked?: boolean
  contentId?: string
  userStore?: UserStore
  currentProgress?: number
  onVideoPlay?: () => void
  onVideoPause?: () => void
  onVideoEnd?: () => void
  onVideoProgress?: (progress: number) => void
  onVideoPreviewEnd?: () => void
}

interface ExPlaylistItem extends PlaylistItem {
  starttime: number
}

function checkHEVCSupportWeb() {
  var video = document.createElement('video')
  var isHEVCSupported = video.canPlayType('video/mp4; codecs="hev1.1.6.L93.B0"') || video.canPlayType('video/mp4; codecs="hvc1.1.6.L93.B0"')

  return isHEVCSupported
}

export const propsToPlaylistItem = (props: VideoProps): ExPlaylistItem => {
  console.log('props.data?.playlist', props.data?.playlist)

  if (props.data?.playlist && isWeb) {
    const item = props.data.playlist[0] as ExPlaylistItem

    // find source with .m3u8 extension
    const source = item?.sources?.find((source) => source.file.includes('.m3u8'))

    if (source && checkHEVCSupportWeb()) {
      source.file = props.hevcVideo
      item.sources = [source]
    }

    delete item.title
    delete item.description

    item.startTime = props.currentProgress || 0
    item.starttime = props.currentProgress || 0

    return item
  }

  // get version of os
  const { osName, osVersion } = Device
  let file = props.url

  if (osName && osVersion) {
    // get first version of os
    const [osVersionMajorString] = osVersion.split('.')
    const osVersionMajor = parseInt(osVersionMajorString || '0', 10)

    if (isIOS && osVersionMajor >= 12) {
      file = props.hevcVideo
    }

    if (isAndroid && osVersionMajor >= 8) {
      file = props.hevcVideo
    }
  }

  let tracks: any[] = []

  if (props.captions) {
    tracks = [
      {
        file: props.captions,
        label: 'English',
        kind: 'captions',
        default: true
      }
    ]
  }

  return {
    file,
    image: props.thumbnail,
    tracks: tracks || [],
    startTime: props.currentProgress || 0,
    starttime: props.currentProgress || 0,
  }
}
