import React from 'react'
import { SizableText, YStack, useMedia } from '@mythical/ui'
import { QnAQuestionRankingType, QnAUserAnswer } from '../../QnACard.container'
import { Button } from 'app/components/primitive/Button'
import { QnAQuestionRankingList } from './QnAQuestionRankingList'
import QnATimer from '../../QnAQuestionTimer'
import { QnAQuestionHeading } from '../../QnAQuestionHeading'

export interface QnAQuestionRankingProps {
  question: QnAQuestionRankingType
  onTimeOut: (data: QnAUserAnswer) => void
  onSubmit: (data: QnAUserAnswer) => void
  submitting?: boolean
}

export const QnAQuestionRanking = ({
  question,
  onSubmit,
  onTimeOut,
  submitting,
}: QnAQuestionRankingProps) => {
  const media = useMedia()
  const [hasMadeSelection, setHasMadeSelection] = React.useState(false)
  const answersWithOrder = question.answers.map((answer, index) => ({
    ...answer,
    order: index + 1,
  }))

  const [data, setData] =
    React.useState<typeof answersWithOrder>(answersWithOrder)

  const handleSetAnswers = (data: typeof answersWithOrder) => {
    setData(data)
    setHasMadeSelection(true)
  }

  return (
    <YStack gap="$2.5" w="100%" $gtXxs={{ gap: '$3.5' }}>
      <YStack gap="$3.5" ai="center">
        {question.timeLimit > 0 && (
          <QnATimer
            size={media.gtSm ? 70 : 42}
            strokeWidth={media.gtSm ? 7 : 4}
            fontSize={media.gtSm ? 32 : 24}
            duration={question.timeLimit}
            onComplete={() =>
              onTimeOut({
                answerIds: data.map((a) => a.id),
                questionId: question.id,
              })
            }
            id={question.id}
          />
        )}
        <YStack gap="$2">
          <QnAQuestionHeading question={question.question} />
          <SizableText
            tag="p"
            maxWidth={400}
            mx="auto"
            color="$primary10"
            size="$3"
            ta="center"
            $gtXxs={{
              size: '$4',
            }}
          >
            {question.instructions ||
              `Drag & drop the answers in the correct to complete the task!`}
          </SizableText>
        </YStack>
      </YStack>
      <QnAQuestionRankingList answers={data} setAnswers={handleSetAnswers} />
      <Button
        disabled={!hasMadeSelection || submitting}
        onPress={() =>
          onSubmit({
            answerIds: data.map((a) => a.id),
            questionId: question.id,
          })
        }
        size="$3"
        mx="auto"
        $gtSm={{
          size: '$4',
        }}
        loading={submitting}
      >
        Submit
      </Button>
    </YStack>
  )
}
