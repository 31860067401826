import {
  isWeb,
  SizableText,
  XStack,
  YStack,
  YStackProps,
  Stack,
} from '@mythical/ui'
import { SrOnly } from 'app/components/primitive/SrOnly'
import React from 'react'
import ContentThumbnail from '../ContentThumbnail'
import { addDays } from 'date-fns'
import { formatUpcomingDate } from './helpers'
import { OptionalLink } from 'app/components/primitive/OptionalLink'
import { LinkCore } from 'solito/link'
import { Tag } from 'app/components/primitive/Tag/Tag'
import {
  FIRST_DEGREE_TIER,
  INITIATE_TIER,
  SECOND_DEGREE_TIER,
  THIRD_DEGREE_TIER,
} from 'app/constants/tiers'

type TierTag = { label: string; href: string }

const contentTierToTags = (contentTier: string): TierTag[] => {
  const tags: TierTag[] = []

  if (contentTier === 'free' || tags.length > 0) {
    tags.push({
      label: INITIATE_TIER.name,
      href: INITIATE_TIER.href,
    })
  }

  if (contentTier === '_1' || tags.length > 0) {
    tags.push({
      label: FIRST_DEGREE_TIER.name,
      href: FIRST_DEGREE_TIER.href,
    })
  }

  if (contentTier === '_2' || tags.length > 0) {
    tags.push({
      label: SECOND_DEGREE_TIER.name,
      href: SECOND_DEGREE_TIER.href,
    })
  }

  if (contentTier === '_3' || tags.length > 0) {
    tags.push({
      label: THIRD_DEGREE_TIER.name,
      href: THIRD_DEGREE_TIER.href,
    })
  }

  return tags
}

export interface ContentCardUpcomingProps {
  id: string
  image: string
  title: string
  contentTier: string | number
  dateReleaseStart: string
  dateReleaseEnd?: string
  href?: string
  wrapperProps?: YStackProps
  onNavigate?: () => void
}

const ContentCardUpcoming = ({
  image,
  title,
  href,
  dateReleaseStart = new Date().toISOString(),
  dateReleaseEnd = addDays(new Date(), 3).toISOString(),
  contentTier = 'free',
  wrapperProps,
  onNavigate,
}: ContentCardUpcomingProps) => {
  const formattedDate = formatUpcomingDate(dateReleaseStart, dateReleaseEnd)
  const tags = contentTierToTags(contentTier + '')

  return (
    <YStack
      jc="flex-start"
      ai="flex-start"
      w="100%"
      bg="$secondary9"
      br="$3"
      overflow="hidden"
      {...wrapperProps}
    >
      <OptionalLink
        href={href}
        Component={YStack}
        componentProps={{
          width: '100%',
          tag: 'a',
          tabIndex: -1,
          ...(href && {
            onPress: onNavigate,
            pressStyle: {
              opacity: 0.7,
            },
          }),
        }}
      >
        <ContentThumbnail
          image={image}
          hidePlayButton
          wrapperProps={{ bblr: '$0', bbrr: '$0' }}
        />
        <Stack
          pos="absolute"
          top={0}
          py="$1.5"
          px="$2.5"
          bbrr="$2"
          left={0}
          bg="$primary10"
        >
          <SizableText>{formattedDate}</SizableText>
        </Stack>
        {isWeb && <SrOnly>Open ${title}</SrOnly>}
      </OptionalLink>

      <YStack py="$3" px="$2.5" gap="$2" bg="$secondary9" w="100%">
        <OptionalLink
          href={href}
          Component={YStack}
          componentProps={{
            tag: 'a',
            gap: '$1.5',
            ...(href && {
              pressStyle: { opacity: 0.7 },
              hoverStyle: { opacity: 0.8 },
              onPress: onNavigate,
            }),
          }}
        >
          <XStack gap="$1">
            {tags.map((tag) => (
              <Stack key={tag.label} mr="$2">
                <LinkCore
                  href={tag.href}
                  Component={Tag}
                  componentProps={{
                    tag: 'a',
                    variant: 'secondary',
                    displaySize: 'lg',
                    uppercase: false,
                  }}
                >
                  {tag.label}
                </LinkCore>
              </Stack>
            ))}
          </XStack>
          <SizableText
            tag="h3"
            color="$primary10"
            size="$4"
            fontWeight="600"
            mt="$2"
          >
            {title}
          </SizableText>
        </OptionalLink>
      </YStack>
    </YStack>
  )
}

export default ContentCardUpcoming
