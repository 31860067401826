import React, { memo } from 'react'
import { Circle, Path } from 'react-native-svg'

import { IconProps } from '../IconProps'
import { IconBase } from '../IconBase'
import { themed } from '../themed'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Circle cx="12" cy="12" r="3" stroke={`${color}`} />
      <Path
        d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"
        stroke={`${color}`}
      />
    </IconBase>
  )
}

Icon.displayName = 'IconEye'

export const IconEye = memo<IconProps>(themed(Icon))
