import React from 'react'
import { YStack, Dialog, YStackProps, isWeb } from '@mythical/ui'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { Button } from 'app/components/primitive/Button'
import { IconClose } from 'app/components/primitive/Icon'
import { BlurView } from 'expo-blur'
import { QnAGameState } from './QnACard'

// Dialogs on native render outside of the root view, so we need to wrap them
// in a GestureHandlerRootView to ensure that gestures work properly.
const OptionalGestureHandlerRootView = ({ children }) => {
  if (!isWeb) {
    return (
      <GestureHandlerRootView
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        {children}
      </GestureHandlerRootView>
    )
  }

  return children
}

interface QnADialogProps {
  title?: string
  open: boolean
  onOpenChange: (open: boolean) => void
  children?: React.ReactNode
  contentProps?: YStackProps
  state: QnAGameState
}

export const QnADialog = ({
  title,
  open,
  onOpenChange,
  children,
  state,
  contentProps,
}: QnADialogProps) => {
  return (
    <Dialog key="QnADialog" open={open} onOpenChange={() => {}}>
      <Dialog.Portal>
        <OptionalGestureHandlerRootView>
          <Dialog.Overlay key="overlay" bg="$backdrop" opacity={0.33} />
          <BlurView
            intensity={15}
            tint="dark"
            style={{ position: 'absolute', width: '100%', height: '100%' }}
          />
          <Dialog.Content
            p="$3.5"
            key="QnADialogContent"
            width="100%"
            elevation="$0"
            maxWidth={800}
            minHeight={420}
            backgroundColor="$transparent"
            shadowColor="$transparent"
            borderWidth={0}
            opacity={1}
            animateOnly={['transform', 'opacity']}
            animation={[
              'quick',
              {
                opacity: {
                  overshootClamping: true,
                },
              },
            ]}
            enterStyle={{ x: 0, opacity: 0 }}
            exitStyle={{ x: 0, opacity: 0 }}
          >
            <YStack jc="center" gap="$2.5" w="100%" $gtXs={{ gap: '$3.5' }}>
              <YStack
                borderWidth={1}
                boc="$primary10"
                br="$3"
                overflow="hidden"
                {...contentProps}
              >
                {title && (
                  <Dialog.Title
                    size="$8"
                    fow="500"
                    color="$primary10"
                    tt="uppercase"
                    ta="center"
                    bg="$secondary9"
                    pt="$3.5"
                  >
                    {title}
                  </Dialog.Title>
                )}

                {children}
              </YStack>

              {state !== QnAGameState.STARTED && (
                <Dialog.Close asChild>
                  <Button
                    plain
                    theme="white"
                    position="absolute"
                    top="$-5"
                    pb="$3.5"
                    right="$-2"
                    size="$2"
                    icon={<IconClose size={24} color="$white" />}
                    onPress={() => onOpenChange(false)}
                    focusStyle={{
                      outlineWidth: 0,
                    }}
                  />
                </Dialog.Close>
              )}
            </YStack>
          </Dialog.Content>
        </OptionalGestureHandlerRootView>
      </Dialog.Portal>
    </Dialog>
  )
}
