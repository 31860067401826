import { Stack, useMedia, XStack } from '@mythical/ui'
import Section from 'app/components/structure/Section'
import React from 'react'

import ContentCard, { ContentCardProps } from '../ContentCard'
import RelatedContentCarousel from '../RelatedContent/RelatedContentCarousel'

type LatestContentHeroProps = {
  items: ContentCardProps[]
}

const LatestContentHero = ({ items }: LatestContentHeroProps) => {
  const media = useMedia()
  if (!items.length)
    return <Stack py="$2" bg="$secondary10" $gtSm={{ py: '$3' }} />

  if (media.xs) {
    return (
      <Section padded jc="center" ai="center">
        <RelatedContentCarousel items={items} />
      </Section>
    )
  }

  return (
    <Section padded>
      <XStack space="$3" jc="center" mx="auto" maxWidth={800} w="100%">
        {items.map((item) => (
          <Stack w="50%" key={item.id}>
            <ContentCard {...item} textCenter />
          </Stack>
        ))}
      </XStack>
    </Section>
  )
}

export default LatestContentHero
