import React from 'react'

import SpotifyEmbed from '../primitive/SpotifyEmbed'
import { SpotifyEmbedProps } from '../primitive/SpotifyEmbed/SpotifyEmbedProps'

interface Props {
  block: SpotifyEmbedProps
}

export const SpotifyEmbedBlock: React.FC<Props> = ({ block }) => {
  return <SpotifyEmbed {...block} />
}
