import React from 'react'
import { FieldValues, useController } from 'react-hook-form'
import { formatISO } from 'date-fns'
import { RhfProps } from '../RhfProps'
import { DatePickerProps } from './DatePickerProps'
import { DatePicker } from './DatePicker'

export type DatePickerRhf<T extends FieldValues> = RhfProps<T> &
  DatePickerProps

export function DatePickerRhf<T extends FieldValues>({
  name,
  defaultValue,
  control,
  rules = {},
  required,
  ...datePickerProps
}: DatePickerRhf<T>) {
  if (required) {
    rules.required = 'This field is required'
  }
  const startController = useController({
    name,
    control,
    rules,
    defaultValue,
  })

  let hasError = !!startController.fieldState.error
  const convertToDate = (date: Date | string | null) =>
    !date ? null : typeof date === 'string' ? new Date(date) : date

  return (
    <DatePicker
      {...datePickerProps}
      startDate={
        startController.field.value
          ? convertToDate(startController.field.value)
          : null
      }
      isRangePicker={false}
      required={!!rules?.required}
      onChange={(data) => {
        const startValue = data.startDate
          ? formatISO(data.startDate, { representation: 'date' })
          : ''
        startController.field.onChange(startValue)
      }}
      helperText={
        hasError
          ? startController.fieldState.error?.message
          : datePickerProps.helperText
      }
      error={hasError}
    />
  )
}
