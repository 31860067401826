import React, { useState } from 'react'
import { YStack } from '@mythical/ui'
import { QnATimer } from '../QnATimer'
import { QnAQuestionType, QnAUserAnswer } from '../QnACard.container'

import { QnAQuestionChoice } from './QnAQuestionChoice'
import { QnAQuestionRanking } from './QnAQuestionRanking'

type RenderQuestionProps = {
  question: QnAQuestionType
  onSubmit: (data: QnAUserAnswer) => void
  onTimeOut: (data: QnAUserAnswer) => void
  submitting?: boolean
}

const RenderQuestion = (props: RenderQuestionProps) => {
  const { question, onSubmit, onTimeOut } = props
  switch (question.__typename) {
    case 'Ranking':
      return (
        <QnAQuestionRanking
          question={question}
          onSubmit={onSubmit}
          onTimeOut={onTimeOut}
          submitting={props.submitting}
        />
      )
    case 'MultipleChoice':
    case 'SingleChoice':
      return (
        <QnAQuestionChoice
          question={question}
          onSubmit={onSubmit}
          onTimeOut={onTimeOut}
          submitting={props.submitting}
        />
      )
    default:
      return null
  }
}

export type QnAQuestionProps = {
  question: QnAQuestionType
  closingDate: string
  onSubmit: (data: QnAUserAnswer) => void
  onTimeOut: (data: QnAUserAnswer) => void
  submitting?: boolean
}

export const QnAQuestion = (props: QnAQuestionProps) => {
  const [submitted, hasSubmitted] = useState(false)
  const { question, closingDate } = props

  const onSubmit = (data: QnAUserAnswer) => {
    hasSubmitted(prev => {
      if (prev) return

      props.onSubmit(data)
      return true
    })
  }

  const onTimeOut = (data: QnAUserAnswer) => {
    hasSubmitted(prev => {
      if (prev) return

      props.onTimeOut(data)
      return true
    })
  }

  return (
    <YStack
      pos="relative"
      bg="$secondary9"
      jc="center"
      ai="center"
      p="$3.5"
      gap="$3.5"
      $gtSm={{ px: '$6', py: '$4' }}
    >
      <RenderQuestion
        question={question}
        onSubmit={onSubmit}
        onTimeOut={onTimeOut}
        submitting={props.submitting}
      />
      <QnATimer date={closingDate} label="ENDS IN" />
    </YStack>
  )
}
