import React, { useRef } from 'react'
import { useDatePickerContext } from './DatePickerContext'
import { useDay } from '@datepicker-react/hooks'
import { SizableText, Stack } from '@mythical/ui'

export type DayProps = {
  date: Date
  dayLabel: string
}

const getColor = (
  isSelected: boolean,
  isSelectedStartOrEnd: boolean,
  isWithinHoverRange: boolean,
  isDisabled: boolean
) => {
  return ({
    selectedFirstOrLastColor,
    normalColor,
    selectedColor,
    rangeHoverColor,
    disabledColor,
  }: {
    selectedFirstOrLastColor: string
    normalColor: string
    selectedColor: string
    rangeHoverColor: string
    disabledColor: string
  }) => {
    if (isSelectedStartOrEnd) {
      return selectedFirstOrLastColor
    } else if (isSelected) {
      return selectedColor
    } else if (isWithinHoverRange) {
      return rangeHoverColor
    } else if (isDisabled) {
      return disabledColor
    } else {
      return normalColor
    }
  }
}

export function Day({ dayLabel, date }: DayProps) {
  const dayRef = useRef(null)
  const datepickerContext = useDatePickerContext()
  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
    onClick,
    onMouseEnter,
  } = useDay({
    date,
    dayRef,
    ...datepickerContext,
  })

  if (!dayLabel) {
    return <Stack display={'flex'} flex={1} />
  }

  const getColorFn = getColor(
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate
  )

  return (
    <SizableText
      ref={dayRef as any}
      onPress={onClick as any}
      onHoverIn={onMouseEnter as any}
      disabled={disabledDate}
      width={`${100 / 7}%`}
      textAlign={'center'}
      paddingVertical={'$2'}
      cursor={'pointer'}
      br="$2"
      backgroundColor={getColorFn({
        selectedFirstOrLastColor: '$secondary8',
        normalColor: '$secondary9',
        selectedColor: '$primary10',
        rangeHoverColor: '$primary9',
        disabledColor: '$secondary4',
      })}
      hoverStyle={{
        backgroundColor: getColorFn({
          selectedFirstOrLastColor: '$secondary8',
          normalColor: '$secondary8',
          selectedColor: '$primary9',
          rangeHoverColor: '$primary8',
          disabledColor: '$secondary4',
        }),
      }}
    >
      {dayLabel}
    </SizableText>
  )
}
