import { isWeb } from '@mythical/ui'
import { Quiz } from 'app/blocks/homepage/quiz'
import { QnACard } from 'app/components/content/QnACard'
import Section from 'app/components/structure/Section'
import React from 'react'

export const QuizBlock: React.FC<{ block: Quiz }> = ({ block }) => {
  const { current, previous, next } = block


  if (!current && !previous && !next) {
    return null
  }

  // don't render if we are on web and it's not running locally
  if (isWeb && !__DEV__) {
    return null
  }

  const key = `${current?.id}-${previous?.id}-${next?.id}`

  return (
    <Section padded>
      <QnACard {...(block as any)} key={key} />
    </Section>
  )
}
