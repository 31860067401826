import { isWeb, SizableText, XStack, YStack, YStackProps } from '@mythical/ui'
import { Tag } from 'app/components/primitive/Tag/Tag'
import { SrOnly } from 'app/components/primitive/SrOnly'
import React from 'react'
import { LinkCore } from 'solito/link'

import ContentThumbnail, { ContentThumbnailProps } from '../ContentThumbnail'
import ContentTierBadge from '../ContentTierBadge'

export interface ContentCardXLProps {
  id: string
  image: string
  title: string
  datePublished: string
  duration?: string
  commentCount: number
  category: string
  href: string
  contentTier: number
  categoryHref?: string
  wrapperProps?: YStackProps
  thumbnailProps?: ContentThumbnailProps['wrapperProps']
  onNavigate?: () => void
  disableComments?: boolean
}

const ContentCardXL = ({
  image,
  title,
  duration,
  datePublished,
  commentCount,
  category,
  href,
  contentTier,
  categoryHref,
  thumbnailProps,
  wrapperProps,
  onNavigate,
  disableComments,
}: ContentCardXLProps) => {
  return (
    <YStack
      flex={1}
      jc="flex-start"
      ai="flex-start"
      w="100%"
      bg="$secondary9"
      br="$2"
      overflow="hidden"
      {...wrapperProps}
    >
      <LinkCore
        href={href}
        tabIndex={-1}
        Component={YStack}
        componentProps={{
          width: '100%',
          tag: 'a',
          onPress: onNavigate,
          pressStyle: {
            opacity: 0.7,
          },
          ...thumbnailProps,
        }}
      >
        <ContentThumbnail
          image={image}
          duration={duration}
          hidePlayButton={true}
          aspectRatio={47 / 27}
          wrapperProps={{
            bblr: '$0',
            bbrr: '$0',
          }}
        />
        {isWeb && <SrOnly>Open ${title}</SrOnly>}
      </LinkCore>

      <XStack
        flex={1}
        py="$3"
        px="$3.5"
        pl="$2.5"
        gap="$2"
        bg="$secondary9"
        w="100%"
      >
        <YStack jc="center" flexShrink={0}>
          <ContentTierBadge tier={contentTier} w={50} h={50} />
        </YStack>
        <YStack jc="flex-start" ai="flex-start" gap="$2" flex={1}>
          {category && categoryHref && (
            <LinkCore
              href={categoryHref}
              Component={Tag}
              componentProps={{ tag: 'a', size: 'sm', variant: 'secondary' }}
            >
              {category}
            </LinkCore>
          )}
          <LinkCore
            href={href}
            Component={YStack}
            componentProps={{
              tag: 'a',
              gap: '$2.5',
              flex: 1,
              pressStyle: { opacity: 0.7 },
              hoverStyle: { opacity: 0.8 },
              onPress: onNavigate,
            }}
          >
            <SizableText tag="h3" color="$primary1" size="$4">
              {title}
            </SizableText>
            <SizableText color="$secondary4" size="$2">
              {datePublished}{' '}
              {commentCount > 0 && !disableComments && (
                <>
                  •{' '}
                  <SizableText color="$secondary5" size="$2">
                    {commentCount} COMMENTS
                  </SizableText>
                </>
              )}
            </SizableText>
          </LinkCore>
        </YStack>
      </XStack>
    </YStack>
  )
}

export default ContentCardXL
