import { gql } from 'graphql-request'

export const createFetchPollQuery = () => gql`
  query getPoll($id: String!) {
    Polls(id: $id) {
      id
      questions {
        ... on SingleChoice {
          question
          answers {
            id
            answer
            percent
            yourVote
          }
        }
      }
      closingDate
    }
  }
`

export const mapToPoll = (data: any) => {
  return data.Polls
}
