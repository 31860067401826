import { YStack } from '@mythical/ui'
import { FAQs as FAQsType } from 'app/blocks/faqs'
import { mapExcludedPlatforms } from 'app/utils/mapExcludedPlatforms'
import React from 'react'
import { Platform } from 'react-native'

import Faq from '../primitive/Faq'

interface Props {
  block: FAQsType
}

export const FAQsBlock: React.FC<Props> = ({ block }) => {
  const faqs = block.faqs.filter(
    (faq) => !mapExcludedPlatforms(faq.excludedPlatforms)?.includes(Platform.OS)
  )
  return (
    <YStack space="$3">
      {faqs.length > 0
        ? faqs.map((faq) => <Faq key={faq.id} {...faq} />)
        : null}
    </YStack>
  )
}
