import React from 'react'
import { createContext, PropsWithChildren, ReactNode, useContext } from 'react'

interface DatePickerContext {
  rtl?: boolean
  focusedDate: Date | null
  goToPreviousMonths: () => void
  goToPreviousMonthsByOneMonth: () => void
  goToNextMonths: () => void
  goToNextMonthsByOneMonth: () => void
  goToPreviousYear: (numYears?: number) => void
  goToNextYear: (numYears?: number) => void

  onDateFocus(date: Date): void
  onDateSelect(date: Date): void
  onDateHover(date: Date): void

  isDateFocused(date: Date): boolean
  isDateSelected(date: Date): boolean
  isDateHovered(date: Date): boolean
  isDateBlocked(date: Date): boolean
  isFirstOrLastSelectedDate(date: Date): boolean

  onDayRender?(date: Date): ReactNode
}

export const datePickerContextDefaultValue = {
  rtl: false,
  focusedDate: null,
  isDateFocused: () => false,
  isDateSelected: () => false,
  isDateHovered: () => false,
  isDateBlocked: () => false,
  isFirstOrLastSelectedDate: () => false,
  onDateFocus: () => {},
  onDateHover: () => {},
  onDateSelect: () => {},
  onDayRender: undefined,
  goToPreviousMonthsByOneMonth: () => {},
  goToPreviousMonths: () => {},
  goToNextMonthsByOneMonth: () => {},
  goToNextMonths: () => {},
  goToPreviousYear: () => {},
  goToNextYear: () => {},
}

const DatePickerContext = createContext<DatePickerContext>(
  datePickerContextDefaultValue
)

export type DatePickerProviderProps = PropsWithChildren<DatePickerContext>

export const DatePickerProvider = ({
  children,
  ...value
}: DatePickerProviderProps) => {
  return (
    <DatePickerContext.Provider value={value}>
      {children}
    </DatePickerContext.Provider>
  )
}

export const useDatePickerContext = () => useContext(DatePickerContext)
