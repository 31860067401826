import React, { useEffect, useMemo } from 'react'
import { PollCardComponent, PollCardComponentProps } from './PollCard'

import useMutation from 'app/hooks/use-mutation'
import { voteMutation, voteResult } from 'app/mutations/polls/vote'
import { createFetchPollQuery, mapToPoll } from 'app/queries/polls/fetch-poll'
import useQuery from 'app/hooks/use-query'

const fetchPollQuery = createFetchPollQuery()
const voteQuery = voteMutation()

export type PollAnswer = {
  id: string
  answer: string
  percent?: number
  yourVote?: boolean
}

export type PollCardProps = {
  id: string
  questionId: string
  question: string
  answers: PollAnswer[]
  closingDate?: string
} & Pick<PollCardComponentProps, 'displaySize'>

export const PollCard = (props: PollCardProps) => {
  const { id, question, questionId, closingDate } = props

  const [voteLoadingId, setVoteLoadingId] = React.useState<string | null>(null)
  const [userVoteId, setUserVoteId] = React.useState<string | null>(null)
  const [answers, setAnswers] = React.useState<PollAnswer[]>(props.answers)

  const mutation = useMutation(voteQuery, voteResult)

  const params = useMemo(() => {
    return {
      id,
    }
  }, [id])

  const query = useQuery(fetchPollQuery, params, mapToPoll, {
    enabled: !!mutation.data?.success,
  })

  const handleVote = async (answerId: string) => {
    const variables = {
      id,
      answers: [
        {
          questionId,
          answerIds: [answerId],
        },
      ],
    };

    setVoteLoadingId(answerId)

    await mutation.makeRequest(variables)
    await query.fetchData()
  }

  const userVote = answers.find((answer) => answer.yourVote)

  if (userVote && !userVoteId) {
    setUserVoteId(userVote.id)
  }

  useEffect(() => {
    if (!query.data) return
    const poll = query.data

    const userVote = poll.questions[0].answers.find((answer) => answer.yourVote)

    if (userVote) {
      setUserVoteId(userVote.id)
      setVoteLoadingId(null)
    }

    setAnswers(poll.questions[0].answers)
  }, [query.data])

  return (
    <PollCardComponent
      answers={answers}
      endDate={closingDate}
      question={question}
      onVote={handleVote}
      userVoteId={userVoteId}
      voteLoadingId={voteLoadingId}
      displaySize={props.displaySize}
    />
  )
}
