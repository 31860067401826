import { Image, YStack, YStackProps } from '@mythical/ui'
import { OptionalLink } from 'app/components/primitive/OptionalLink'
import React from 'react'

const imageMap = {
  free: require('app/assets/images/content-badge-tier-0.png'),
  1: require('app/assets/images/content-badge-tier-1.png'),
  2: require('app/assets/images/content-badge-tier-2.png'),
  3: require('app/assets/images/content-badge-tier-3.png'),
}

const interactionStyles = {
  hoverStyle: {
    opacity: 0.8,
  },
  pressStyle: {
    opacity: 0.7,
  },
  focusStyle: {
    opacity: 0.8,
  },
}

export type ContentTierBadgeProps = {
  tier?: keyof typeof imageMap | number
  href?: string
} & YStackProps

const ContentTierBadge = ({
  tier = 'free',
  href,
  ...props
}: ContentTierBadgeProps) => {
  const tierImage = imageMap[tier] || imageMap['free']

  const wrapperProps = {
    ...(href && interactionStyles),
    ...props,
  }

  return (
    <OptionalLink
      Component={YStack}
      componentProps={wrapperProps}
      {...(href && { href })}
    >
      <Image
        top="-5%"
        style={{ width: '100%', height: '100%' }}
        source={{ uri: tierImage }}
      />
    </OptionalLink>
  )
}

export default ContentTierBadge
