import React from 'react'
import { ButtonLink } from '../../primitive/Button'
import Section from '../../structure/Section'
import { LandingHero as LandingHeroBlockType } from 'app/blocks/landing/landing-hero'
import SectionIntroLarge from '../../structure/SectionIntro/SectionIntroLarge'
import { Container, H2, Paragraph } from '@mythical/ui'
import { YStack } from '@mythical/ui'
import { VideoBlock } from '../Video'
import { isIOS } from 'app/utils/constants'

interface LandingHeroBlockProps {
  block: LandingHeroBlockType
}

export const LandingHeroBlock = ({ block }: LandingHeroBlockProps) => {
  const { mainTitle, mainSubtitle, heroVideo } = block
  return (
    <YStack>
      <SectionIntroLarge
        title={mainTitle}
        description={mainSubtitle}
        paddedX
        paddedTop
      />
      <Section padded bg="$secondary10" bbw={1} bbc="$primary10">
        <Container gap="$5" pb="$4">
          <YStack w="100%">
            {heroVideo?.video?.[0] && (
              <VideoBlock
                block={heroVideo?.video?.[0]}
                thumbnail={heroVideo.thumbnail?.sizes?.thumbnail?.url}
                isUnlocked
              />
            )}
          </YStack>
          <YStack
            maxWidth={512}
            w="100%"
            mx="auto"
            gap="$2"
            jc="center"
            ai="center"
          >
            {!isIOS && (
              <>
                <H2
                  textTransform="uppercase"
                  fontWeight="700"
                  textAlign="center"
                  color="$primary1"
                  size="$8"
                >
                  {heroVideo?.title}
                </H2>
                <Paragraph
                  pb="$3"
                  size="$4"
                  textAlign="center"
                  color="$secondary7"
                >
                  {heroVideo?.subtitle}
                </Paragraph>
                {heroVideo?.ctaLabel && (
                  <ButtonLink href="/perks">{heroVideo?.ctaLabel}</ButtonLink>
                )}
              </>
            )}
          </YStack>
        </Container>
      </Section>
    </YStack>
  )
}
