import React from 'react'
import { Controller, FieldValues } from 'react-hook-form'
import { RhfProps } from '../RhfProps'
import { Input, InputProps } from './Input'

export type InputRhfProps<T extends FieldValues = FieldValues> = InputProps &
  RhfProps<T>

export function InputRhf<T extends FieldValues = FieldValues>({
  name,
  control,
  rules = {},
  defaultValue,
  ...inputProps
}: InputRhfProps<T>) {
  if (inputProps.required) {
    rules.required = 'This field is required'
  }
  return (
    <Controller<T>
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { error },
      }) => (
        <Input
          {...inputProps}
          value={value ?? ''}
          onBlur={onBlur}
          error={!!error}
          onChangeText={onChange}
          helperText={error ? error.message : inputProps.helperText}
        />
      )}
    />
  )
}
