import { SizableText, YStack, Dialog, Unspaced, XStack } from '@mythical/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { Button } from '../primitive/Button'
import { IconClose } from '../primitive/Icon'
import { RedemptionFormValues } from './RedemptionForm'

interface ConfirmAddressModalProps {
  form: RedemptionFormValues
  modalOpen: boolean
  onConfirm: () => void
  setModalOpen: (open: boolean) => void
}

export const ConfirmAddressModal = observer<ConfirmAddressModalProps>(
  ({ modalOpen, form, onConfirm, setModalOpen }) => {
    return (
      <Dialog open={modalOpen} onOpenChange={(open) => setModalOpen(open)}>
        <Dialog.Adapt platform="native" when="xxl">
          <Dialog.Sheet
            zIndex={200000}
            modal
            dismissOnSnapToBottom
            snapPoints={[30, 30]}
          >
            <Dialog.Sheet.Frame bg="$secondary10" p="$3" pt="$4">
              <Dialog.Sheet.Handle />
              <Dialog.Adapt.Contents />
            </Dialog.Sheet.Frame>
            <Dialog.Sheet.Overlay animation="quick" bg="transparent" />
          </Dialog.Sheet>
        </Dialog.Adapt>

        <Dialog.Portal>
          <Dialog.Overlay
            key="overlay"
            animation="quick"
            bg="$backdrop"
            opacity={0.33}
            enterStyle={{ opacity: 0 }}
            exitStyle={{ opacity: 0 }}
          />
          <Dialog.Content
            borderWidth={1}
            borderColor="$secondary10"
            elevation="$4"
            enterStyle={{ x: 0, y: -10, opacity: 0 }}
            exitStyle={{ x: 0, y: -10, opacity: 0 }}
            bg="$secondary9"
            x={0}
            y={0}
            opacity={1}
            animation={[
              'quick',
              {
                opacity: {
                  overshootClamping: true,
                },
              },
            ]}
            elevate
          >
            <YStack jc="center" space>
              <Dialog.Title textAlign="center" color="$primary1">
                Confirm Address
              </Dialog.Title>
              <Dialog.Description textAlign="center" color="$primary1">
                Please confirm the address below is correct, as you cannot
                change it once submitted.
              </Dialog.Description>
              <SizableText color="$primary1" size="$3">
                {form.address1}
              </SizableText>
              {form.address2 && (
                <SizableText color="$primary1" size="$3">
                  {form.address2}
                </SizableText>
              )}
              <SizableText color="$primary1" size="$3">
                {form.city}
                {', '}
                {form.provinceCode}
              </SizableText>
              <SizableText color="$primary1" size="$3">
                {form.zip}
              </SizableText>

              <XStack space="$2" jc="center">
                <Button onPress={() => setModalOpen(false)}>Cancel</Button>
                <Button
                  theme="tertiary"
                  aria-label="Confirm"
                  onPress={onConfirm}
                >
                  Confirm
                </Button>
              </XStack>

              <Unspaced>
                <Dialog.Close asChild>
                  <Button
                    position="absolute"
                    top="$-2"
                    right="$-2"
                    size="$3"
                    circular
                    icon={<IconClose />}
                  />
                </Dialog.Close>
              </Unspaced>
            </YStack>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog>
    )
  }
)
