import { Mutation } from 'app/types/mutation'
import { gql } from 'graphql-request'

export const trackViewsMutation = gql`
  mutation track($events: [EventInput!]!) {
    trackView(events: $events) {
      success
      reason
    }
  }
`

export const trackViewsResult = (data: any) => {
  return data.trackView as Mutation
}
