import { Stack, YStack } from '@mythical/ui'
import React from 'react'
import { PaginationDots } from 'app/components/primitive/PaginationDots'
import { QnAQuestionType } from '../QnACard.container'
import { QnARenderQuestionAnswers } from './QnARenderQuestionAnswers'
import { useSwiperControls } from 'app/hooks/use-swiper-controls'
import { Swiper, SwiperSlide } from 'swiper/react'

type QnAAnswersProps = {
  questions: QnAQuestionType[]
  pagination?: boolean
}

const QnAAnswers = ({
  questions,
  pagination = true,
}: QnAAnswersProps) => {
  const { swiperProps, activeSlide, slideTo } = useSwiperControls()

  return (
    <YStack w="100%" gap="$3.5">
      <Stack fd="row" ai="flex-start" jc="flex-start">
        <style jsx global>{`
          .QnAAnswersSlide {
            width: 100%;
          }
        `}</style>
        <Swiper mousewheel slidesPerView="auto" {...swiperProps}>
          {questions.map((question) => (
            <SwiperSlide key={question.id} className="QnAAnswersSlide">
              <Stack pr="$3">
                <QnARenderQuestionAnswers question={question} />
              </Stack>
            </SwiperSlide>
          ))}
        </Swiper>
      </Stack>
      {pagination && questions.length > 1 && (
        <PaginationDots
          count={questions.length}
          activeIndex={activeSlide}
          onChange={slideTo}
        />
      )}
    </YStack>
  )
}

export default QnAAnswers
