import { Headline, SizableText } from '@mythical/ui'
import Spinner from 'app/components/primitive/Spinner'
import Section from 'app/components/structure/Section'
import React from 'react'

import ContentCarousel from './EpisodesContentCarousel'
import { ContentCardEpisodeProps } from '../ContentCardEpisode'

interface EpisodesContentCarouselProps {
  items: ContentCardEpisodeProps[]
  titlePrefix: string
  title: string
  ctaHref?: string
  ctaLabel?: string
  loading?: boolean
}

const EpisodesContentCarousel = ({
  titlePrefix,
  title,
  ctaHref,
  ctaLabel,
  items,
  loading,
}: EpisodesContentCarouselProps) => {
  return (
    <Section
      padded
      title={`${titlePrefix.trim()} `}
      titleHighlight={title}
      ctaHref={ctaHref}
      ctaLabel={ctaLabel}
    >
      {loading && <Spinner p="$4" size="large" />}
      {items?.length > 0 && <ContentCarousel items={items} />}
      {!loading && items?.length === 0 && (
        <SizableText textTransform="uppercase" color="$secondary5">
          No episodes found
        </SizableText>
      )}
    </Section>
  )
}

export default EpisodesContentCarousel
