import React, { useState } from 'react'
import { SolitoImage } from 'solito/image'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import { Stack, StackProps } from '@mythical/ui'

export interface ZoomableImageProps {
  src: string
  srcZoomed: string
  wrapperProps?: StackProps
  contentFit?: 'cover' | 'contain'
  alt?: string
  sizes?: string
  imageProps?: any
}

/**
 * A zoomable image component. Will show a thumbnail image, and when clicked, will zoom in to the full image.
 * Specify `sizes` to control the image resolutions at different breakpoints.
 */
export const ZoomableImage = ({
  src,
  srcZoomed,
  sizes,
  contentFit = 'cover',
  alt,
  wrapperProps,
  imageProps,
}: ZoomableImageProps) => {
  const [zoomed, setZoomed] = useState(false)
  return (
    <ControlledZoom
      classDialog="ImageZoom"
      isZoomed={zoomed}
      onZoomChange={setZoomed}
      zoomImg={{ src: srcZoomed }}
    >
      <Stack aspectRatio={16 / 9} overflow="hidden" br="$2" {...wrapperProps}>
        <SolitoImage
          src={zoomed ? srcZoomed : src}
          fill
          contentFit={contentFit}
          alt={alt || ''}
          sizes={zoomed ? '100vw' : sizes}
          {...imageProps}
        />
      </Stack>
    </ControlledZoom>
  )
}
