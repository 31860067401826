import { CategoryLinks } from 'app/blocks/homepage/category-links'
import TagCarousel from 'app/components/primitive/TagCarousel'
import { mapToContentTabs } from 'app/queries/content/tabs'
import React from 'react'

export const CategoryLinksBlock: React.FC<{ block: CategoryLinks }> = ({
  block,
}) => {
  const { links } = block || {}

  if (!links || links?.length === 0) {
    return null
  }

  const formatted = mapToContentTabs({ contentTabs: links })

  return <TagCarousel items={formatted || []} />
}
