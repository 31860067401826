import React from 'react'
import ContentCard, { Poll } from '../ContentCard'
import { PollCard } from '../PollCard'

const renderItem = ({ item }, showPolls?: boolean, fullWidth?: boolean) => {
  let component: React.ReactNode = null
  if (
    item.poll &&
    item.poll?.questions?.length === 1 &&
    item.poll?.questions[0] &&
    showPolls &&
    !item.hidePoll
  ) {
    const poll = item.poll as Poll
    component = (
      <PollCard
        id={poll.id}
        questionId={poll.questions[0]?.id || ''}
        question={poll.questions[0]?.question || ''}
        answers={poll.questions[0]?.answers || []}
        closingDate={poll?.closingDate || ''}
      />
    )
  } else {
    component = (
      <ContentCard
        textCenter={fullWidth}
        hideInfo
        thumbnailProps={{
          $xs: {
            borderRadius: '$0',
          },
        }}
        {...item}
      />
    )
  }
  return component
}

export default renderItem
