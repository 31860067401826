import { SizableText, Stack, XStack, YStack } from '@mythical/ui'
import React, { useState } from 'react'
import { Pressable } from 'react-native'

import { IconArrowDown } from '../Icon/icons/IconArrowDown'
import { IconArrowUp } from '../Icon/icons/IconArrowUp'
import RichText from '../RichText'

type FaqProps = {
  question: string
  answer: string
}

// TODO:
// Add accessibility labels
// Animate height

const Faq = ({ question, answer }: FaqProps) => {
  const [open, setOpen] = useState(false)

  return (
    <YStack
      bg="$secondary9"
      p="$3"
      borderRadius="$3"
      hoverStyle={{
        bg: '$secondary8',
      }}
      focusStyle={{
        bg: '$secondary8',
      }}
      animation="quick"
      w="100%"
    >
      <Pressable onPress={() => setOpen(!open)}>
        <XStack space="$2" jc="space-between" w="100%">
          <SizableText
            size="$4"
            flexShrink={1}
            textTransform="uppercase"
            color="$primary10"
            $gtXs={{ size: '$5' }}
          >
            {question}
          </SizableText>
          <Stack
            flexShrink={0}
            animation="quick"
            rotate={open ? '180deg' : '0deg'}
          >
            <IconArrowUp size={24} color="$primary10" />
          </Stack>
        </XStack>
      </Pressable>
      <YStack overflow="hidden" height={open ? 'auto' : 0}>
        <YStack pt="$3">
          <RichText content={answer} color="$secondary4" />
        </YStack>
      </YStack>
    </YStack>
  )
}

export default Faq
