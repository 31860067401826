import { RichText as RichTextType } from 'app/blocks/rich-text'
import React from 'react'

import RichText, { RichTextProps } from '../primitive/RichText'

interface Props {
  block: RichTextType
  textProps: RichTextProps['textProps']
}

export const RichTextBlock: React.FC<Props> = ({ block, textProps }) => {
  return <RichText content={block.content} textProps={textProps} />
}
