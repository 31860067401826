import React from 'react'
import { YStack } from '@mythical/ui'
import { QnAAnswer } from '../QnAAnswer'
import { QnAQuestionType } from '../QnACard.container'
import { QnAQuestionHeading } from '../QnAQuestionHeading'

type QnARenderQuestionAnswersProps = {
  question: QnAQuestionType
}

export const QnARenderQuestionAnswers = (
  props: QnARenderQuestionAnswersProps
) => {
  const { question } = props

  switch (question.__typename) {
    case 'Ranking':
      const orderedUserAnswers = question.answers.sort((a, b) => {
        const positionA =
          a.correctPosition !== undefined ? a.correctPosition : 0
        const positionB =
          b.correctPosition !== undefined ? b.correctPosition : 0

        return positionA - positionB
      })

      return (
        <YStack gap="$3" $gtXxs={{ gap: '$3.5' }}>
          <QnAQuestionHeading question={question.question} />
          <YStack gap="$2">
            {orderedUserAnswers.map((answer, index) => (
              <QnAAnswer
                key={answer.id}
                answer={answer.answer}
                isCorrect={answer.correctPosition === answer.yourPosition}
                answerSelected={answer.yourPosition !== undefined}
                order={index + 1}
              />
            ))}
          </YStack>
        </YStack>
      )

    case 'MultipleChoice':
    case 'SingleChoice':
      const answersAlphabeticOrder = question.answers.map((answer, index) => ({
        ...answer,
        order: String.fromCharCode(65 + index),
      }))

      return (
        <YStack gap="$3" $gtXxs={{ gap: '$3.5' }}>
          <QnAQuestionHeading question={question.question} />
          <YStack gap="$2">
            {answersAlphabeticOrder.map((answer) => (
              <QnAAnswer
                key={answer.id}
                answer={answer.answer}
                isCorrect={answer.correct}
                answerSelected={!!answer.yourVote}
                order={answer.order}
              />
            ))}
          </YStack>
        </YStack>
      )
    default:
      return null
  }
}
