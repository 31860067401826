export type TierConstant = {
  name: string
  href: string
}

export const INITIATE_TIER: TierConstant = {
  name: 'Initiate',
  href: '/tier/initiate'
}

export const FIRST_DEGREE_TIER: TierConstant = {
  name: '1st',
  href: '/tier/1st-degree'
}

export const SECOND_DEGREE_TIER: TierConstant = {
  name: '2nd',
  href: '/tier/2nd-degree'
}

export const THIRD_DEGREE_TIER: TierConstant = {
  name: '3rd',
  href: '/tier/3rd-degree'
}
