import { ExternalImage as ExternalImageType } from 'app/blocks/external-image'
import { Image as ImageType } from 'app/blocks/image'
import { ZoomableImage } from 'app/components/primitive/ZoomableImage/index.web'
import React from 'react'

interface Props {
  block: ImageType | ExternalImageType
}

export const ImageBlock: React.FC<Props> = ({ block }) => {
  const imageUri =
    block.__typename === 'ImageBlock' ? block.image.url : block.url

  return (
    <ZoomableImage
      src={imageUri}
      alt={block.caption}
      srcZoomed={imageUri}
      contentFit="contain"
      sizes="(max-width: 1020px) 100vw, 1300px"
    />
  )
}
