import React, { memo } from 'react'
import { Circle } from 'react-native-svg'

import { IconProps } from '../IconProps'
import { IconBase } from '../IconBase'
import { themed } from '../themed'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 11 26"
      fill="none"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Circle cx={2} cy={2.5} r={2} fill={color} />
      <Circle cx={9} cy={2.5} r={2} fill={color} />
      <Circle cx={2} cy={9.5} r={2} fill={color} />
      <Circle cx={9} cy={9.5} r={2} fill={color} />
      <Circle cx={2} cy={16.5} r={2} fill={color} />
      <Circle cx={9} cy={16.5} r={2} fill={color} />
      <Circle cx={2} cy={23.5} r={2} fill={color} />
      <Circle cx={9} cy={23.5} r={2} fill={color} />
    </IconBase>
  )
}

Icon.displayName = 'IconDrag'

export const IconDrag = memo<IconProps>(themed(Icon))
