import React from 'react'
import { Button, H5, H6, SizableText, Stack, Theme, XStack } from '@mythical/ui'
import {
  ArrowLeft,
  ArrowRight,
  ChevronsLeft,
  ChevronsRight,
  ChevronLeft,
  ChevronRight,
} from '@tamagui/lucide-icons'
import { useMonth, UseMonthProps } from '@datepicker-react/hooks'
import { Day } from './Day'
import { useDatePickerContext } from './DatePickerContext'

export type MonthProps = UseMonthProps & {
  onPrevious?: () => void
  onNext?: () => void
  monthsCount: number
  isFirst: boolean
  isLast: boolean
}

export function Month({
  onPrevious,
  onNext,
  monthsCount,
  isFirst,
  isLast,
  ...props
}: MonthProps) {
  const { days, weekdayLabels, monthLabel } = useMonth({
    ...props,
    monthLabelFormat(date: Date) {
      return new Intl.DateTimeFormat(undefined, { month: 'long' }).format(date)
    },
  })
  const {
    goToPreviousYear,
    goToPreviousMonths,
    goToPreviousMonthsByOneMonth,
    goToNextMonthsByOneMonth,
    goToNextMonths,
    goToNextYear,
  } = useDatePickerContext()

  const { year } = props

  return (
    <Theme name="outline">
      <Stack width={250} $xs={{ width: '100%' }}>
        <XStack space={'$2'} justifyContent={'center'} alignItems={'center'}>
          <Button
            size={'$2'}
            chromeless
            focusable={false}
            onPress={() => goToPreviousYear(10)}
            icon={<ChevronsLeft />}
          />
          <Button
            size={'$2'}
            chromeless
            focusable={false}
            onPress={() => goToPreviousYear(1)}
            icon={<ChevronLeft />}
          />
          <H6>{year}</H6>
          <Button
            size={'$2'}
            chromeless
            focusable={false}
            onPress={() => goToNextYear(1)}
            icon={<ChevronRight />}
          />
          <Button
            size={'$2'}
            chromeless
            focusable={false}
            onPress={() => goToNextYear(10)}
            icon={<ChevronsRight />}
          />
        </XStack>

        <XStack
          justifyContent={'space-between'}
          alignItems={'center'}
          height={40}
        >
          <Button
            focusable={false}
            opacity={isFirst ? undefined : 0}
            disabled={!isFirst}
            onPress={
              monthsCount > 1
                ? goToPreviousMonths
                : goToPreviousMonthsByOneMonth
            }
            icon={<ArrowLeft />}
            chromeless
          />

          <H5>{monthLabel}</H5>

          <Button
            focusable={false}
            opacity={isLast ? undefined : 0}
            disabled={!isLast}
            onPress={
              monthsCount > 1 ? goToNextMonths : goToNextMonthsByOneMonth
            }
            icon={<ArrowRight />}
            chromeless
          />
        </XStack>
        <XStack flex={7} width={250} $xs={{ width: '100%' }}>
          {weekdayLabels.map((dayLabel) => (
            <SizableText
              width={`${100 / 7}%`}
              key={dayLabel}
              textAlign={'center'}
              paddingVertical={'$2'}
            >
              {dayLabel}
            </SizableText>
          ))}
        </XStack>
        <XStack
          display={'flex'}
          flexWrap={'wrap'}
          width={250}
          $xs={{ width: '100%' }}
        >
          {days.map((day, index) => {
            if (typeof day === 'object') {
              return (
                <Day
                  dayLabel={day.dayLabel}
                  date={day.date}
                  key={day.date.toString()}
                />
              )
            }
            return <Stack key={index} width={`${100 / 7}%`} />
          })}
        </XStack>
        <XStack display={'flex'}></XStack>
      </Stack>
    </Theme>
  )
}
