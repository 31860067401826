import { Stack, YStack } from '@mythical/ui'
import { LinearGradient } from '@tamagui/linear-gradient'
import { Button } from 'app/components/primitive/Button'
import {
  IconChevronLeft,
  IconChevronRight,
} from 'app/components/primitive/Icon'
import { useSwiperControls } from 'app/hooks/use-swiper-controls'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import ContentCardEpisode, {
  ContentCardEpisodeProps,
} from '../ContentCardEpisode'

type EpisodesContentCarouselProps = {
  items: ContentCardEpisodeProps[]
}

const EpisodesContentCarousel = ({ items }: EpisodesContentCarouselProps) => {
  const { swiperProps, hasPrev, hasNext, handleNext, handlePrev } =
    useSwiperControls()

  return (
    <Stack fd="row" ai="flex-start" jc="flex-start">
      <style jsx global>{`
        .EpisodesContentCarouselSlide {
          width: 60%;
          min-width: 250px;
          @media (min-width: 660px) {
            width: 40%;
            min-width: 300px;
          }
        }
      `}</style>
      <Swiper mousewheel slidesPerView="auto" {...swiperProps}>
        {items.map((item, i) => (
          <SwiperSlide key={item.id} className="EpisodesContentCarouselSlide">
            <Stack pr="$3">
              <ContentCardEpisode {...item} isActive={false} />
            </Stack>
          </SwiperSlide>
        ))}
      </Swiper>
      <YStack
        zIndex={1}
        position="absolute"
        l="$0"
        h="100%"
        opacity={hasPrev ? 1 : 0}
        x={hasPrev ? 0 : -100}
        animation="quick"
        $xs={{
          display: 'none',
        }}
      >
        <LinearGradient
          position="absolute"
          className="pointer-events-none"
          l="$-3"
          w="$8"
          $gtXs={{
            w: '$10',
          }}
          h="100%"
          start={[1, 1]}
          end={[1, 1]}
          locations={[0.25, 1]}
          colors={['#1E2807', 'transparent']}
        />
        <YStack height="100%" ai="center" jc="center">
          <Button
            circular
            icon={<IconChevronLeft size={32} color="$primary1" />}
            onPress={handlePrev}
            accessibilityLabel="Previous"
          />
        </YStack>
      </YStack>

      <YStack
        zIndex={1}
        position="absolute"
        r="$0"
        h="100%"
        x={hasNext ? 0 : 100}
        opacity={hasNext ? 1 : 0}
        animation="quick"
        $xs={{
          display: 'none',
        }}
      >
        <LinearGradient
          position="absolute"
          className="pointer-events-none"
          r="$-3"
          h="100%"
          w="$8"
          $gtXs={{
            w: '$10',
          }}
          start={[1, 1]}
          end={[1, 1]}
          locations={[0, 0.8]}
          colors={['transparent', '#1E2807']}
        />
        <YStack height="100%" ai="center" jc="center">
          <Button
            circular
            icon={<IconChevronRight size={32} color="$primary1" />}
            onPress={handleNext}
            accessibilityLabel="Next"
          />
        </YStack>
      </YStack>
    </Stack>
  )
}

export default EpisodesContentCarousel
