import { H2, isWeb, styled, XStack, YStack } from '@mythical/ui'
import { UserArea } from 'app/components/user/UserArea'
import config from 'app/config'
import { useStores } from 'app/store'
import React, { useEffect, useState } from 'react'
import InAppBrowser from 'react-native-inappbrowser-reborn'

import { ButtonLink } from '../Button'
import Spinner from '../Spinner'

interface Props {
  slug?: string
}

export const Title = styled(H2, {
  size: '$8',
  fontFamily: '$heading',
  textTransform: 'uppercase',
  fontWeight: '500',
  textAlign: 'center',
  color: '$primary10',
})

const SignupConfirmation = ({ slug }: Props) => {
  const { userStore } = useStores()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!userStore) return
    if (!userStore?.loginUrl) return

    const url = userStore?.loginUrl as string

    if (!userStore?.user) {
      userStore?.setReturnPath(`/pages/${slug}`)

      setTimeout(() => {
        if (isWeb) {
          window.location.href = url
        } else {
          InAppBrowser.openAuth(url, config?.redirectUrl || '', {
            showTitle: false,
            enableUrlBarHiding: true,
            enableDefaultShare: false,
            ephemeralWebSession: false,
          })
        }
      }, 1000)
    } else {
      // we are already logged in but we might need to refresh the session
      userStore?.refreshAccessToken().then(() => {
        setLoading(false)
      })
    }

    return () => {
      userStore?.setReturnPath('')
    }
  }, [userStore, slug])

  if (!userStore) return null

  if (!slug && !userStore?.user) {
    return (
      <YStack px="$4" alignItems="center">
        <Title>You must be logged in to view the confirmation page.</Title>
      </YStack>
    )
  }

  if (!userStore?.user) {
    return (
      <YStack px="$4" alignItems="center" space="$4">
        <XStack alignItems="center" alignContent="center" space="$4">
          <Title>Logging you in...</Title>
          <Spinner />
        </XStack>

        <ButtonLink auth href={userStore?.loginUrl || '/'}>
          Click here if you are not redirected
        </ButtonLink>
      </YStack>
    )
  }

  return (
    <YStack px="$4" alignItems="center" space="$4">
      <Title>Your details</Title>

      {loading && (
        <XStack alignItems="center" alignContent="center" space="$4">
          <Spinner />
        </XStack>
      )}

      {!loading && <UserArea />}
    </YStack>
  )
}

export default SignupConfirmation
