import { HomepageFeatured } from 'app/blocks/homepage/homepage-featured'
import FeaturedContentCarousel from 'app/components/content/FeaturedContentCarousel'
import React from 'react'

export const HomepageFeaturedBlock: React.FC<{ block: HomepageFeatured }> = ({
  block,
}) => {
  const { homepageFeaturedTitle, content } = block || {}

  if (!content || content?.length === 0) {
    return null
  }

  return (
    <FeaturedContentCarousel
      items={content || []}
      centered
      showPolls
      title={homepageFeaturedTitle || "Featured"}
    />
  )
}
