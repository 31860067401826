import { v4 as uuidv4 } from 'uuid';
import { UserStore } from 'app/store/user-store';
import config from 'app/config'
import makeSiteGraphqlRequest from 'app/lib/graphql-request'
import { trackViewsMutation, trackViewsResult } from 'app/mutations/track/track';
import getEnv from './env-getter';

const BATCH_SIZE = 10;
const SEND_INTERVAL_MS = 5000;
const STORAGE_KEY = 'unsentEvents';

interface Event {
  sessionId: string;
  content: string;
  viewedAt: string;
  duration?: number;
  videoProgress?: number;
}

class EventBatcher {
  events: Event[] = [];
  timer: number | null = null;
  sessionId: string = uuidv4();
  store: UserStore;
  sending: boolean = false;

  constructor(store: UserStore) {
    this.store = store;

    window.addEventListener('beforeunload', this.sendEvents.bind(this));

    this.startTimer();
    this.loadEventsFromStorage();
  }

  loadEventsFromStorage(): void {
    if (getEnv() !== 'production') return
    const storedEvents = localStorage.getItem(STORAGE_KEY);
    if (storedEvents) {
      this.events = JSON.parse(storedEvents);
    }
  }

  storeEventsToStorage(): void {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(this.events));
  }

  addEvent(event: Event): void {
    this.events.push(event);

    if (this.events.length >= BATCH_SIZE) {
      this.sendEvents();
    }
  }

  async sendEvents(): Promise<void> {
    if (this.sending) return;
    if (this.events.length === 0) return;

    if (!navigator.onLine) {
      this.storeEventsToStorage();
      return;
    }

    this.sending = true;

    try {
      const events = this.events.map((event) => ({
        ...event,
        viewedAt: new Date().toISOString(),
        sessionId: this.sessionId,
      }));

      const res = await makeSiteGraphqlRequest(
        config,
        trackViewsMutation,
        {
          events,
        },
        {
          userStore: this.store,
          formatFn: trackViewsResult
        }
      );

      if (!res.success) {
        console.error('Error sending events:', res.reason);
        return;
      }

      // Clear events from the array after they have been sent
      this.events = [];
      this.storeEventsToStorage();
    } catch (error) {
      console.error('Error sending events:', error);
    }

    this.sending = false;
  }

  startTimer(): void {
    this.stopTimer();
    this.timer = window.setInterval(this.sendEvents.bind(this), SEND_INTERVAL_MS);
  }

  stopTimer(): void {
    if (this.timer !== null) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  static instance: EventBatcher
  static getInstance(store?: UserStore) {
    if (!EventBatcher.instance && store) {
      EventBatcher.instance = new EventBatcher(store)
    }

    if (!EventBatcher.instance && !store) {
      throw new Error('EventBatcher not initialized')
    }

    return EventBatcher.instance
  }
}

export default EventBatcher;
