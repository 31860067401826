import { ContinueWatching } from 'app/blocks/homepage/continue-watching'
import FeaturedContentCarousel from 'app/components/content/FeaturedContentCarousel'
import React from 'react'

export const ContinueWatchingBlock: React.FC<{ block: ContinueWatching }> = ({
  block,
}) => {
  const { continueWatchingTitle, content } = block || {}

  console.log('block', block)

  if (!content || content?.length === 0) {
    return null
  }

  const processed = content.map((item) => {
    return {
      ...item,
     secondsWatched: block.timeMap.find((time) => time.contentId === item.id)?.secondsWatched,
    }
  })

  return (
    <FeaturedContentCarousel
      items={processed || []}
      centered
      title={continueWatchingTitle || "Continue Watching"}
      pagination={false}
    />
  )
}
