import React from 'react'
import { Select, SelectProps } from './Select'
import { Controller, FieldValues } from 'react-hook-form'
import { RhfProps } from '../RhfProps'

export type SelectRhfProps<T extends FieldValues> = SelectProps &
  RhfProps<T> & {}

export function SelectRhf<T extends FieldValues>({
  name,
  control,
  rules = {},
  defaultValue,
  ...inputProps
}: SelectRhfProps<T>) {
  if (inputProps.required) {
    rules.required = 'This field is required'
  }
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value },
        fieldState: { error, invalid },
      }) => (
        <Select
          {...inputProps}
          value={value ?? ''}
          error={invalid}
          onValueChange={onChange}
          helperText={error ? error.message : inputProps.helperText}
        />
      )}
    />
  )
}
