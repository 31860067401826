import React from 'react'
import {
  Input as InputBase,
  InputProps as InputBaseProps,
  Stack,
  TextArea,
  Theme,
  useTheme,
} from '@mythical/ui'
import { useState } from 'react'
import { FormFieldContainer } from '../FormFieldContainer'
import { FormContainerBaseTypes } from '../FormContainerBaseTypes'
import { Pressable } from 'react-native'
import { IconEye, IconEyeOff } from '../../Icon'
import { IconProps } from '../../Icon/IconProps'
import { color } from '@mythical/ui-config/tokens'

export type InputProps = InputBaseProps &
  FormContainerBaseTypes & {
    multiline?: boolean
    isPassword?: boolean
    fullWidth?: boolean
    passwordIconProps?: IconProps
  }

export function Input({
  required,
  error,
  helperText,
  helperTextProps,
  label,
  labelProps,
  labelInline,
  multiline,
  hideLabel,
  containerProps,
  isPassword,
  fullWidth,
  passwordIconProps,
  theme = 'outline',
  ...rest
}: InputProps) {
  const themeValues = useTheme({ name: theme })
  const [show, setShow] = useState<boolean>(false)
  const id = rest.id
  const currentInputProps = {
    borderRadius: 6,
    placeholderTextColor: color.secondary6,
    ...rest,
  }
  if (error) {
    // currentInputProps.theme = 'red'
    currentInputProps.borderColor = error ? '$red7' : undefined
  }
  if (fullWidth) {
    currentInputProps.minWidth = '100%'
  }

  let secureTextEntry = !!(rest.secureTextEntry || isPassword)

  return (
    <Theme name={theme}>
      <FormFieldContainer
        id={id}
        error={error}
        required={required}
        labelProps={labelProps}
        label={label}
        fullWidth={fullWidth}
        size={rest.size}
        labelInline={labelInline}
        helperText={helperText}
        helperTextProps={helperTextProps}
        hideLabel={hideLabel}
        {...containerProps}
      >
        {multiline ? (
          <TextArea {...currentInputProps} rows={4} numberOfLines={4} />
        ) : secureTextEntry ? (
          <Stack
            position={'relative'}
            width={fullWidth ? '100%' : currentInputProps?.width}
          >
            <InputBase
              {...currentInputProps}
              secureTextEntry={!show}
              autoCapitalize="none"
            />
            <Pressable
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: 0,
                bottom: 0,
                marginVertical: 'auto',
                ...(rest?.direction === 'rtl'
                  ? {
                      left: 15,
                    }
                  : {
                      right: 15,
                    }),
              }}
              onPress={() => {
                setShow((state) => !state)
              }}
            >
              {show ? (
                <IconEye color={themeValues.color.val} {...passwordIconProps} />
              ) : (
                <IconEyeOff
                  color={themeValues.color.val}
                  {...passwordIconProps}
                />
              )}
            </Pressable>
          </Stack>
        ) : (
          <InputBase {...currentInputProps} autoCapitalize="none" />
        )}
      </FormFieldContainer>
    </Theme>
  )
}
