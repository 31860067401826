import { XStack, Text as BaseText, YStack, Stack, styled } from '@mythical/ui'
import useQuery from 'app/hooks/use-query'
import { createUserQuery, userQueryToUser } from 'app/queries/user/cms-user'
import { observer } from 'mobx-react-lite'
import React from 'react'

import Spinner from '../primitive/Spinner'

const query = createUserQuery()
const params = {}

const Text = styled(BaseText, {
  fontFamily: '$body',
  fontSize: '$sm',
  color: '$primary3',
})

const Row = styled(XStack, {
  backgroundColor: '$secondary9',
  padding: '$2',
  justifyContent: 'space-between',
})

export const UserArea = observer(() => {
  const { loading, data, error } = useQuery(query, params, userQueryToUser)

  return (
    <Stack
      maxWidth={480}
      width="100%"
      paddingHorizontal={4}
      nativeID="user-area"
    >
      {loading && !data && <Spinner />}
      {error && <Text>{error.message}</Text>}
      {data && (
        <YStack space="$2" width="100%">
          <Row>
            <Text fontWeight="600">Name</Text>
            <Text nativeID="user-name">{data.fullName}</Text>
          </Row>
          <Row>
            <Text fontWeight="600">Email</Text>
            <Text nativeID="user-email">{data.email}</Text>
          </Row>
          <Row>
            <Text fontWeight="600">Degree</Text>
            <Text nativeID="user-degree">{data?.tier}</Text>
          </Row>
          <Row>
            <Text fontWeight="600">Cost</Text>
            <Text nativeID="user-cost">
              {data?.lastOrderCost ? `$${data?.lastOrderCost / 100}` : 'Free'}
            </Text>
          </Row>
        </YStack>
      )}
    </Stack>
  )
})
