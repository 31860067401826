import React from 'react'
import { SizableText, Stack, YStack } from '@mythical/ui'
import { Button } from 'app/components/primitive/Button'
import { SolitoImage } from 'solito/image'
import { QnATimer } from './QnATimer'

export type QnAIntroProps = {
  title: string
  subtitle?: string
  onStart: () => void
  closingDate: string
  loading?: boolean
  isDialog?: boolean
}

export const QnAIntro = (props: QnAIntroProps) => {
  const { title, subtitle, onStart, closingDate, loading, isDialog } = props
  return (
    <YStack pos="relative" bg="$secondary9" br="$3">
      {isDialog && (
        <Stack
          pos="absolute"
          top={0}
          left={0}
          right={0}
          w="100%"
          h="100%"
          o={0.5}
        >
          <SolitoImage
            src={require('../../../assets/images/qna-bg--secondary6.png')}
            contentFit="cover"
            contentPosition="top center"
            alt=""
            fill
          />
        </Stack>
      )}

      <YStack gap={isDialog ? '$6' : '$3.5'} jc="center" ai="center" p="$3.5">
        {isDialog && (
          <SolitoImage
            src={require('../../../assets/images/logo--full.png')}
            width={64}
            height={103}
            alt=""
          />
        )}
        <YStack gap="$2">
          <SizableText
            tag="h3"
            ff="$heading"
            color="$primary1"
            size="$8"
            fontWeight="500"
            tt="uppercase"
            ta="center"
          >
            {title}
          </SizableText>
          {subtitle && (
            <SizableText
              tag="p"
              maxWidth={400}
              mx="auto"
              color="$primary10"
              size="$4"
              ta="center"
            >
              {subtitle}
            </SizableText>
          )}
        </YStack>
        <Button
          size={isDialog ? '$4' : '$3'}
          uppercase
          onPress={onStart}
          loading={loading}
          disabled={loading}
        >
          Start
        </Button>
        <QnATimer date={closingDate} label="ENDS IN" />
      </YStack>
    </YStack>
  )
}
