import React from 'react'
import { InputRhf } from 'app/components/primitive/Form'
import { Field } from 'app/blocks/form'
import { RhfProps } from 'app/components/primitive/Form/RhfProps'

export const Text: React.FC<Field & RhfProps<any>> = ({
  __typename,
  name,
  label,
  control,
  required: requiredFromProps,
}) => {
  const keyboardType = {
    Email: 'email-address',
    Number: 'numeric',
  }[__typename]

  const autoCompleteType = {
    Email: 'email',
  }[__typename]

  const isTextArea = __typename === 'Textarea'

  return (
    <InputRhf
      id={name}
      name={name}
      label={label}
      keyboardType={keyboardType}
      autoComplete={autoCompleteType}
      multiline={isTextArea}
      control={control}
      required={requiredFromProps}
      theme="outlineSecondary9"
    />
  )
}
