import React from 'react'
import { SizableText, Stack, YStack } from '@mythical/ui'
import { SolitoImage } from 'solito/image'
import { ButtonLink } from 'app/components/primitive/Button'
import { isIOS } from 'app/utils/constants'
import { useStores } from 'app/store'

export type QnACompletedLoggedOutProps = {
  isDialog?: boolean
}

export const QnACompletedLoggedOut = (props: QnACompletedLoggedOutProps) => {
  const { isDialog } = props
  const { userStore } = useStores()
  const loginLink = userStore.generateLoginUrl()

  return (
    <YStack
      pos="relative"
      bg="$secondary9"
      gap="$3.5"
      jc="center"
      ai="center"
      p="$3.5"
      br="$3"
    >
      {isDialog && (
        <Stack w={36} h={57} $gtSm={{ w: 63, h: 103 }}>
          <SolitoImage
            src={require('../../../assets/images/logo--full.png')}
            fill
            alt=""
          />
        </Stack>
      )}

      <YStack gap="$2">
        <SizableText
          tag="h3"
          ff="$heading"
          color="$primary1"
          size="$6"
          fontWeight="500"
          tt="uppercase"
          ta="center"
        >
          {isIOS
            ? 'Sign in to save your scores '
            : 'Create an account to save your scores '}
          and see how you compare to other Mythical Beasts!
        </SizableText>

        <SizableText
          tag="p"
          maxWidth={400}
          mx="auto"
          color="$primary10"
          size="$3"
          ta="center"
          $gtXxxs={{ size: '$4' }}
        >
          Joining the Mythical Society is FREE and gives you access to Never
          Before Seen footage, Exclusive Merch, Banned GMM, Behind The
          Mythicality and much, much more!
        </SizableText>
      </YStack>

      <ButtonLink
        auth
        size="$3"
        theme="outline"
        href={loginLink}
        target="_self"
        transparent
      >
        Log in
      </ButtonLink>

      {!isIOS && (
        <ButtonLink size="$3" href="/perks">
          Sign up
        </ButtonLink>
      )}
    </YStack>
  )
}
