import React from 'react'
import { Field } from 'app/blocks/form'
import { RhfProps } from 'app/components/primitive/Form/RhfProps'
import { SelectRhf } from 'app/components/primitive/Form'

export const Select: React.FC<Field & RhfProps<any>> = ({
  name,
  label,
  control,
  options,
  required: requiredFromProps,
}) => {
  return (
    <SelectRhf
      id={name}
      name={name}
      label={label}
      control={control}
      required={requiredFromProps}
      options={options || []}
      labelProps={{
        fontSize: '$3'
      }}
      theme="outlineSecondary9"
    />
  )
}
