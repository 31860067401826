import {
  Image,
  isWeb,
  SizableText,
  Stack,
  YStack,
  YStackProps,
} from '@mythical/ui'
import React from 'react'
import { LinkCore } from 'solito/link'

export type CollectibleProps = {
  id: string
  title: string
  quarter: string
  image: string
  href: string
  hasContent?: boolean
  isActive?: boolean
} & YStackProps

const CollectibleCardLink = ({ href, children }) => {
  return (
    <LinkCore
      Component={YStack}
      href={href}
      componentProps={{
        tag: 'a',
        scale: 1,
        pressStyle: { scale: 0.95 },
        hoverStyle: { scale: 1.05 },
        animation: 'quick',
        cursor: 'pointer',
      }}
    >
      {children}
    </LinkCore>
  )
}

const CollectibleCard = ({
  title,
  quarter,
  image,
  href,
  isActive,
  hasContent,
  ...props
}: CollectibleProps) => {
  const WrapperEl = href && hasContent ? CollectibleCardLink : YStack
  return (
    <WrapperEl href={href}>
      <YStack
        space="$2"
        {...props}
        scale={isActive ? 1.05 : 0.9}
        animation="quick"
      >
        <YStack
          pos="relative"
          jc="center"
          ai="center"
          scale={isActive ? 1 : 0.9}
          animation="quick"
          {...(isWeb ? { pb: '100%' } : { aspectRatio: 1 })}
        >
          <Stack
            pos="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            m="auto"
            w="94%"
            h="94%"
            bg="$primary1"
            borderRadius={9999}
          />
          <Image
            source={{ uri: image }}
            width="100%"
            height="100%"
            resizeMode="cover"
            top={0}
            pos={isWeb ? 'absolute' : 'relative'}
          />
        </YStack>
        <SizableText
          textAlign="center"
          size="$2"
          fontWeight="500"
          $gtSm={{
            size: '$5',
          }}
          color="$primary6"
        >
          {quarter}
        </SizableText>
        <SizableText
          textAlign="center"
          size="$3"
          fontWeight="400"
          $gtSm={{
            size: '$5',
          }}
          $gtLg={{
            size: '$6',
          }}
          color="$primary1"
          animation="quick"
          scale={isActive ? 1.1 : 1}
        >
          {title}
        </SizableText>
      </YStack>
    </WrapperEl>
  )
}

export default CollectibleCard
