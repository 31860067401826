import React from 'react'
import { Stack, YStack, isWeb } from '@mythical/ui'
import { SolitoImage } from 'solito/image'
import { ButtonLink } from 'app/components/primitive/Button'
import { LinkCore } from 'solito/link'
import Section from 'app/components/structure/Section'

// Web hover styles
const webStyles = (
  <style jsx global>{`
    .AdFullWidth:hover .AdFullWidth__Container,
    .AdFullWidth:focus .AdFullWidth__Container,
    .AdFullWidth:active .AdFullWidth__Container {
      opacity: 1;
    }

    .AdFullWidth__Container {
      transition: opacity 0.25s ease-in-out;
    }

    @media screen and (min-width: 800px) {
      .AdFullWidth__Container {
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
      }
    }
  `}</style>
)

export interface AdFullWidthProps {
  image: string
  ctaLabel: string
  href: string
}

export const AdFullWidth = (props: AdFullWidthProps) => {
  const { image, ctaLabel, href } = props
  return (
    <Section>
      <YStack
        className="AdFullWidth"
        pos="relative"
        jc="center"
        ai="center"
        width="100%"
        gap="$3"
        bg="$secondary10"
        pb="$3"
        {...(isWeb && {
          $gtSm: {
            pb: '$0',
          },
        })}
      >
        {isWeb && webStyles}
        <LinkCore
          href={href}
          Component={Stack}
          componentProps={{
            tag: 'a',
            w: '100%',
            aspectRatio: 34 / 11,
            ...(href?.startsWith('http') ? { target: '_blank' } : {}),
          }}
        >
          <SolitoImage src={image} fill alt="" contentFit="cover" />
        </LinkCore>
        {ctaLabel && (
          <Stack
            className="AdFullWidth__Container"
            jc="center"
            ai="center"
            w="100%"
            {...(isWeb && {
              pointerEvents: 'none',
              $gtSm: {
                bg: '$backdrop',
                pos: 'absolute',
                h: '100%',
              },
            })}
          >
            <ButtonLink
              pointerEvents="auto"
              href={href}
              {...(href?.startsWith('http') ? { target: '_blank' } : {})}
            >
              {ctaLabel}
            </ButtonLink>
          </Stack>
        )}
      </YStack>
    </Section>
  )
}
