import React from 'react'
import { H2, Stack, styled, YStack, GetProps, SizableText } from '@mythical/ui'
import Section, {
  SECTION_PADDING,
  SectionWrapperProps,
} from 'app/components/structure/Section'
import { ButtonLink } from '../../primitive/Button'
import RichText from 'app/components/primitive/RichText'
import { SolitoImage } from 'solito/image'
import { OptionalLink } from 'app/components/primitive/OptionalLink'

const AdWrapper = styled(Stack, {
  variants: {
    alignment: {
      center: {
        px: SECTION_PADDING.x,
        py: '$5',
        $gtSm: {
          py: '$6',
          px: SECTION_PADDING.gtXs.x,
        },
        $gtLg: {
          py: '$8',
          px: SECTION_PADDING.gtLg.x,
        },
      },
      left: {
        px: SECTION_PADDING.x,
        py: SECTION_PADDING.y,
        alignItems: 'flex-start',
        $gtSm: {
          px: SECTION_PADDING.gtXs.x,
        },
        $gtLg: {
          px: SECTION_PADDING.gtLg.x,
        },
      },
    },
    variant: {
      primary: {
        bg: '$primary10',
        $gtSm: {
          bg: '$primary10',
        },
      },
      secondary: {
        bg: '$secondary9',
        $gtSm: {
          bg: '$secondary9',
        },
      },
      transparent: {
        bg: 'transparent',
        py: '$0',
        $gtSm: {
          bg: 'transparent',
          py: '$0',
        },
        $gtLg: {
          py: '$0',
        },
      },
    },
  } as const,
})

type AdWrapperProps = GetProps<typeof AdWrapper>

const AdInner = styled(Stack, {
  w: '100%',
  mx: 'auto',
  gap: '$4',
  fd: 'column',
  $gtSm: {
    ai: 'center',
    gap: '$5',
  },
  $gtLg: { gap: '$8' },
  variants: {
    reverse: {
      true: {
        $gtSm: {
          fd: 'row',
        },
      },
      '...': {
        $gtSm: {
          fd: 'row-reverse',
        },
      },
    },
  } as const,
})

const AdContent = styled(YStack, {
  w: '100%',
  flex: 1,
  gap: '$3',
  jc: 'center',
  borderBottomLeftRadius: '$2',
  borderBottomRightRadius: '$2',
  variants: {
    alignment: {
      center: {
        maxWidth: 450,
        alignItems: 'center',
        w: '100%',
        px: '$4',
        mx: 'auto',
        $gtSm: {
          w: '50%',
        },
      },
      left: {
        w: '100%',
        alignItems: 'flex-start',
        $gtSm: {
          w: '50%',
        },
      },
    },
    variant: {
      primary: {
        bg: 'transparent',
      },
      secondary: {
        bg: '$secondary9',
        $gtSm: {
          bg: 'transparent',
        },
      },
      transparent: {
        bg: 'transparent',
        $gtSm: {
          bg: 'transparent',
        },
      },
    },
  } as const,
})

const AdTitle = styled(H2, {
  size: '$8',
  fontFamily: '$heading',
  textTransform: 'uppercase',
  fontWeight: '500',
  variants: {
    alignment: {
      center: {
        textAlign: 'center',
      },
      left: {
        textAlign: 'left',
      },
    },
    variant: {
      primary: {
        color: '$secondary10',
      },
      secondary: {
        color: '$primary10',
      },
      transparent: {
        color: '$white',
      },
    },
  } as const,
})

const AdText = styled(SizableText, {
  textAlign: 'center',
  size: '$2',
  selectable: true,
  $gtMd: {
    size: '$3',
  },
  variants: {
    alignment: {
      center: {
        textAlign: 'center',
      },
      left: {
        textAlign: 'left',
      },
    },
    variant: {
      primary: {
        color: '$secondary10',
      },
      secondary: {
        color: '$primary1',
        $gtMd: {
          color: '$primary1',
        },
      },
      transparent: {
        color: '$white',
        $gtMd: {
          color: '$white',
        },
      },
    },
  } as const,
})

export interface AdStandardProps extends SectionWrapperProps {
  image: string
  title: string
  description?: string
  richTextDescription?: React.ReactNode
  href?: string
  ctaLabel?: string
  reverse?: boolean
  variant: 'primary' | 'secondary' | 'transparent'
  alignment?: 'center' | 'left'
}

export const AdStandard = ({
  image,
  title,
  description,
  richTextDescription,
  href,
  ctaLabel,
  variant,
  reverse = false,
  alignment = 'center',
  ...props
}: AdStandardProps & AdWrapperProps) => {
  return (
    <Section {...props}>
      <AdWrapper alignment={alignment} variant={variant}>
        <AdInner reverse={reverse}>
          <OptionalLink
            href={href}
            Component={Stack}
            componentProps={{
              tag: 'a',
              tabIndex: -1,
              flexShrink: 1,
              aspectRatio: 16 / 9,
              overflow: 'hidden',
              br: '$3',
              $gtSm: {
                w: '50%',
              },
              hoverStyle: { opacity: 0.9 },
              pressStyle: { opacity: 0.85 },
              focusStyle: { opacity: 0.8 },
              ...(href?.startsWith('http') ? { target: '_blank' } : {}),
            }}
          >
            <SolitoImage
              src={image}
              fill
              alt=""
              contentFit="cover"
              sizes="(min-width: 800px) 100vw, 500px"
            />
          </OptionalLink>

          <AdContent variant={variant} alignment={alignment}>
            <AdTitle variant={variant} alignment={alignment}>
              {title}
            </AdTitle>
            {description && (
              <AdText variant={variant} alignment={alignment}>
                {description}
              </AdText>
            )}
            {richTextDescription && (
              <RichText
                content={richTextDescription}
                textProps={{
                  textAlign: alignment === 'center' ? 'center' : 'left',
                  maxWidth: 450,
                  color: variant === 'primary' ? '$secondary10' : '$white',
                  selectable: true,
                }}
                color="$secondary10"
              />
            )}
            {href && ctaLabel && (
              <ButtonLink
                href={href}
                theme="tertiary"
                {...(href?.startsWith('http') ? { target: '_blank' } : {})}
              >
                {ctaLabel}
              </ButtonLink>
            )}
          </AdContent>
        </AdInner>
      </AdWrapper>
    </Section>
  )
}
