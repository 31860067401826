import { format, getMonth } from 'date-fns'

const formatDate = (date: string) => format(new Date(date), 'MMM').toLocaleUpperCase() + ' ' + format(new Date(date), 'do')

export const formatUpcomingDate = (startDate: string, endDate?: string) => {
  const formattedDateStart = formatDate(startDate)
  const isOnSameMonth =
    endDate &&
    getMonth(new Date(startDate)) === getMonth(new Date(endDate))

  const formattedDateEnd =
    endDate && (isOnSameMonth ? format(new Date(endDate), 'do') : formatDate(endDate))

  return `${formattedDateStart}${formattedDateEnd ? ' - ' + formattedDateEnd : ''}`
}