import React from 'react'
import { Calendar } from '@tamagui/lucide-icons'
import { MonthType } from '@datepicker-react/hooks'
import { DatePickerProps } from './DatePickerProps'
import { XStack, Popover, PopoverProps } from '@mythical/ui'
import { Button, ButtonProps } from '../../Button'
import { Month, MonthProps } from './Month'

type DatePickerPopoverProps = Omit<PopoverProps, 'trigger'> & {
  activeMonths: MonthType[]
  monthsCount: number
  firstDayOfWeek: MonthProps['firstDayOfWeek']
  labelFunctions: DatePickerProps['labelFunctions']
  buttonProps?: ButtonProps
}

export function DatePickerPopover({
  activeMonths,
  monthsCount,
  firstDayOfWeek,
  labelFunctions,
  buttonProps,
  ...popoverProps
}: DatePickerPopoverProps) {
  const content = (
    <XStack space alignItems={'flex-start'}>
      {activeMonths.map((month, index) => (
        <Month
          key={`${month.year}-${month.month}`}
          year={month.year}
          month={month.month}
          monthsCount={monthsCount}
          firstDayOfWeek={firstDayOfWeek}
          isFirst={monthsCount === 0 || index === 0}
          isLast={monthsCount === 0 || index === monthsCount - 1}
          {...labelFunctions}
        />
      ))}
    </XStack>
  )

  return (
    <Popover size="$5" {...popoverProps}>
      <Popover.Trigger asChild>
        <Button
          theme="dark"
          icon={<Calendar />}
          borderTopLeftRadius={0}
          borderBottomLeftRadius={0}
          focusStyle={{
            outlineWidth: 0,
          }}
          {...buttonProps}
        />
      </Popover.Trigger>

      <Popover.Adapt when={'sm'} platform="native">
        <Popover.Sheet modal dismissOnSnapToBottom>
          <Popover.Sheet.Overlay bg="$backdrop" />
          <Popover.Sheet.Handle bg="$white" opacity={0.9} />
          <Popover.Sheet.Frame flexGrow={1} padding="$3.5" borderRadius={0}>
            <Popover.Sheet.ScrollView minHeight="100%" h="100%" flexGrow={1}>
              {content}
            </Popover.Sheet.ScrollView>
          </Popover.Sheet.Frame>
        </Popover.Sheet>
      </Popover.Adapt>

      <Popover.Content
        borderWidth={1}
        borderColor="$primary8"
        bg="$secondary9"
        mx="$3.5"
        {...{
          enterStyle: { x: 0, y: -10, opacity: 0 },
          exitStyle: { x: 0, y: -10, opacity: 0, pointerEvents: 'none' },
          x: 0,
          y: 0,
          o: 1,
          animation: [
            'quick',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ],
        }}
      >
        {content}
      </Popover.Content>
    </Popover>
  )
}
