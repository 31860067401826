import React, { useEffect, useState } from 'react'
import { SizableText, Stack, XStack, YStack, styled } from '@mythical/ui'
import { Button } from 'app/components/primitive/Button'
import { PollAnswer } from './PollCard.container'
import { formateDistanceToNow } from './helpers'

const PollWrapper = styled(YStack, {
  backgroundColor: '$primary10',
  br: '$3',
  gap: '$3.5',
  jc: 'center',
  fg: 1,
  variants: {
    canVote: {
      true: {
        backgroundColor: '$primary10',
      },
      false: {
        backgroundColor: '$secondary9',
      },
    },
    displaySize: {
      sm: {
        px: '$3.5',
        py: '$5',
      },
      lg: {
        p: '$5',
      },
    },
  } as const,
  defaultVariants: {
    displaySize: 'sm',
  },
})

const PollQuestion = styled(SizableText, {
  ta: 'center',
  maxWidth: '90%',
  mx: 'auto',
  variants: {
    canVote: {
      true: {
        color: '$secondary10',
      },
      false: {
        color: '$secondary1',
      },
    },
    displaySize: {
      sm: {
        size: '$7',
        fontWeight: '600',
      },
      lg: {
        fontFamily: '$heading',
        size: '$9',
        fontWeight: '700',
        textTransform: 'uppercase',
      },
    },
  } as const,
  defaultVariants: {
    displaySize: 'sm',
  },
})

type VoteResultProps = {
  isUserVote: boolean
  percentage: number
  displaySize?: 'sm' | 'lg'
} & PollAnswer

const VoteResult = (props: VoteResultProps) => {
  const { isUserVote, percentage, answer, displaySize = 'sm' } = props
  return (
    <XStack
      borderWidth={2}
      br="$2"
      borderColor={isUserVote ? '$primary1' : '$transparent'}
      h={50}
      overflow="hidden"
    >
      <XStack br="$2" pos="relative" bg="$secondary8" w="100%">
        <Stack
          pos="absolute"
          left={0}
          h="100%"
          bg={isUserVote ? '$primary8' : '$secondary7'}
          br="$1"
          w={`${percentage}%`}
        />
        <XStack
          px="$3.5"
          w="100%"
          alignItems="center"
          justifyContent="space-between"
          zIndex="$1"
        >
          <SizableText
            size={displaySize === 'sm' ? '$4' : '$6'}
            color={'$white'}
            $xs={{
              fontSize: '$4',
              lineHeight: '$4',
            }}
          >
            {answer}
          </SizableText>
          <SizableText
            color="$white"
            size={displaySize === 'sm' ? '$2' : '$4'}
            $xs={{ size: '$2' }}
          >
            {Math.round(percentage)}%
          </SizableText>
        </XStack>
      </XStack>
    </XStack>
  )
}

export type PollCardComponentProps = {
  question: string
  endDate?: string
  answers: PollAnswer[]
  onVote: (answerId: string) => void
  userVoteId: string | null
  voteLoadingId: string | null
  displaySize?: 'sm' | 'lg'
}

export const PollCardComponent = (props: PollCardComponentProps) => {
  const {
    question,
    endDate,
    answers,
    userVoteId,
    onVote,
    voteLoadingId,
    displaySize = 'sm',
  } = props

  const [displayedEndDate, setDisplayedEndDate] = useState('')

  const hasEnded = endDate && (new Date(endDate) < new Date())
  const canVote = !userVoteId && !hasEnded

  useEffect(() => {
    if (!endDate || hasEnded) return
    const interval = setInterval(() => {
      setDisplayedEndDate(formateDistanceToNow(new Date(endDate)))
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <PollWrapper displaySize={displaySize} canVote={canVote}>
      <PollQuestion tag="h3" displaySize={displaySize} canVote={canVote}>
        {question}
      </PollQuestion>

      {canVote && (
        <YStack gap="$2">
          {answers.map((answer, i) => {
            return (
              <Button
                key={answer.id}
                onPress={() => onVote(answer.id)}
                size="$4"
                fontSize={displaySize === 'sm' ? '$4' : '$6'}
                lineHeight={displaySize === 'sm' ? '$4' : '$6'}
                h={50}
                jc="flex-start"
                br="$2"
                theme="secondary8"
                loading={voteLoadingId === answer.id}
                uppercase={false}
                $xs={{
                  fontSize: '$4',
                  lineHeight: '$4',
                }}
              >
                {answer.answer}
              </Button>
            )
          })}
        </YStack>
      )}

      {!canVote && (
        <YStack gap="$2">
          {answers.map((vote, i) => (
            <VoteResult
              key={vote.id}
              isUserVote={userVoteId === vote.id}
              percentage={vote.percent || 0}
              displaySize={displaySize}
              {...vote}
            />
          ))}
        </YStack>
      )}

      {endDate && !hasEnded && (
        <SizableText
          ta="center"
          size="$4"
          color={canVote ? '$secondary9' : '$white'}
        >
          ENDS IN:{' '}
          <SizableText size="$4" color={canVote ? '$secondary9' : '$primary10'}>
            {displayedEndDate}
          </SizableText>
        </SizableText>
      )}

      {hasEnded && (
        <SizableText
          ta="center"
          size="$4"
          color={canVote ? '$secondary9' : '$white'}
        >
          THIS POLL HAS NOW ENDED
        </SizableText>
      )}
    </PollWrapper>
  )
}
