import React, { useState, useCallback } from 'react'
import { buildInitialFormState } from './form-state-builder'
import { Form, YStack } from '@mythical/ui'

import { fields } from 'app/components/blocks/Form/fields'
import { useForm } from 'react-hook-form'

import { Form as FormType } from 'app/blocks/form'
import { useRouter } from 'solito/router'
import RichText from 'app/components/primitive/RichText'
import { Button } from 'app/components/primitive/Button'
import { formResult, submitMutation } from 'app/mutations/forms/submit'
import useMutation from 'app/hooks/use-mutation'
import { SizableText } from 'tamagui'

export type Value = unknown

export interface Property {
  [key: string]: Value
}

export interface Data {
  [key: string]: Value | Property | Property[]
}

const mutation = submitMutation()

export const FormBlock: React.FC<{
  block: FormType & {
    __typename: 'FormBlock'
  }
}> = (props) => {
  const {
    form: formFromProps,
    form: {
      id: formID,
      submitButtonLabel,
      confirmationType,
      redirect,
      confirmationMessage,
    } = {},
  } = props.block

  console.log('formFromProps', props)

  const formMethods = useForm({
    defaultValues: buildInitialFormState(formFromProps.fields),
  })

  const { makeRequest } = useMutation(mutation, formResult)

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = formMethods

  const [isLoading, setIsLoading] = useState(false)
  const [hasSubmitted, setHasSubmitted] = useState<boolean>()
  const [error, setError] = useState<
    { status?: string; message: string } | undefined
  >()
  const router = useRouter()

  const onSubmit = useCallback(
    (data: Data) => {
      let loadingTimerID: any

      const submitForm = async () => {
        setError(undefined)

        const dataToSend = Object.entries(data).map(([name, value]) => ({
          field: name,
          value: (value as any).toString(),
        }))

        // delay loading indicator by 1s
        loadingTimerID = setTimeout(() => {
          setIsLoading(true)
        }, 1000)

        try {
          const res = await makeRequest({
            id: formID,
            data: dataToSend,
          })

          if (!res?.success) {
            setError({
              message: res?.reason || 'Something went wrong.',
            })
          }

          clearTimeout(loadingTimerID)

          setIsLoading(false)
          setHasSubmitted(true)

          if (confirmationType === 'redirect' && redirect) {
            const { url } = redirect

            const redirectUrl = url

            if (redirectUrl) router.push(redirectUrl)
          }
        } catch (err) {
          console.warn(err)
          setIsLoading(false)
          setError({
            message: 'Something went wrong.',
          })
        }
      }

      submitForm()
    },
    [router, formID, redirect, confirmationType]
  )

  return (
    <Form
      gap="$3.5"
      w="100%"
      flex={1}
      onSubmit={handleSubmit((data) => {
        onSubmit(data)
      })}
    >
      {!isLoading && hasSubmitted && confirmationType === 'message' && (
        <RichText content={confirmationMessage} />
      )}
      {error && (
        <YStack>
          <SizableText>{`${error.status || 'Error'}: ${
            error.message || ''
          }`}</SizableText>
        </YStack>
      )}
      {!hasSubmitted && (
        <>
          <YStack gap="$3.5">
            {formFromProps &&
              formFromProps.fields &&
              formFromProps.fields.map((field, index) => {
                const Field: React.FC<any> = fields?.[field.__typename]
                console.log('field', field)
                if (Field) {
                  return (
                    <React.Fragment key={index}>
                      <Field
                        form={formFromProps}
                        {...field}
                        {...formMethods}
                        register={register}
                        errors={errors}
                        control={control}
                      />
                    </React.Fragment>
                  )
                }
                return null
              })}
          </YStack>

          <Form.Trigger asChild fd="row" ai="center">
            <Button size="$4" loading={isLoading && !hasSubmitted}>
              {submitButtonLabel}
            </Button>
          </Form.Trigger>
        </>
      )}
    </Form>
  )
}
