import { NextUp } from 'app/blocks/homepage/next-up'
import EpisodesContentCarousel from 'app/components/content/EpisodesContentCarousel'
import React from 'react'

export const NextUpBlock: React.FC<{ block: NextUp }> = ({ block }) => {
  const { nextUpTitle, content } = block || {}

  if (!content || content?.length === 0) {
    return null
  }

  return (
    <EpisodesContentCarousel
      titlePrefix={nextUpTitle || "Next up in"}
      title={block.collection.title}
      items={content || []}
    />
  )
}
