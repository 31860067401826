import { Mutation } from 'app/types/mutation'
import { gql } from 'graphql-request'

export const submitMutation = () => gql`
  mutation submitForm(
    $id: String!
    $data: [mutationFormSubmission_SubmissionDataInput]
  ) {
    createFormSubmission(data: { form: $id, submissionData: $data }) {
      id
    }
  }
`

export const formResult = (data: any) => {
  return {
    success: !!data.createFormSubmission.id,
    reason: 'Form submitted successfully',
    id: data.createFormSubmission.id,
  } as Mutation
}
