import React from 'react'
import { SizableText, XStack, YStack } from '@mythical/ui'
import { LinkCore } from 'solito/link'
import { SolitoImage } from 'solito/image'
import { ButtonLink } from '../Button'

export interface ProductCardProps {
  id: string
  image: string
  title?: string
  discount?: number // 0 - 100
  price: number
  description?: string
  ctaLabel?: string
  href: string
}

export const ProductCard = (props: ProductCardProps) => {
  const {
    image,
    title,
    discount,
    price,
    description,
    href,
    ctaLabel = 'View Product',
  } = props
  const discountedPrice = discount && price - (price * discount) / 100
  return (
    <YStack
      gap="$3"
      p="$3.5"
      bg="$secondary7"
      br="$3"
      flex={1}
      $gtSm={{ fd: 'row', ai: 'flex-start', gap: '$3.5' }}
      overflow="hidden"
    >
      <LinkCore
        href={href}
        tabIndex={-1}
        Component={YStack}
        aria-label="Open product page"
        componentProps={{
          position: 'relative',
          tag: 'a',
          cursor: 'pointer',
          br: '$3',
          overflow: 'hidden',
          $gtSm: {
            flexBasis: '30%',
          },
          $gtLg: {
            flexBasis: 200,
          },
        }}
      >
        <YStack
          w="100%"
          scale={1}
          aspectRatio={1}
          hoverStyle={{
            scale: 1.2,
          }}
          animation={[
            'quick',
            {
              scale: {
                overshootClamping: true,
              },
            },
          ]}
        >
          <SolitoImage
            src={image}
            fill
            alt={title || 'Product Image'}
            contentFit="cover"
            sizes="(min-width: 768px) 100vw, 350px"
          />
        </YStack>
      </LinkCore>

      {discount && (
        <XStack
          pos="absolute"
          jc="center"
          bg="$primary10"
          p="$2"
          zIndex="$1"
          top={25}
          right={-100}
          rotate="45deg"
          w={300}
          $gtSm={{
            top: 'unset',
            right: 'unset',
            bottom: 25,
            left: -100,
          }}
        >
          <SizableText
            ta="center"
            ff="$heading"
            color="$white"
            size="$6"
            fow="500"
          >
            {discount}% OFF{' '}
          </SizableText>
        </XStack>
      )}

      <YStack gap="$4" flex={1} height="100%">
        <LinkCore
          href={href}
          tabIndex={-1}
          Component={YStack}
          aria-label="Open product page"
          componentProps={{
            width: '100%',
            tag: 'a',
            gap: '$1',
            pressStyle: {
              opacity: 0.7,
            },
            $gtXs: {
              gap: '$2',
            },
          }}
        >
          <XStack gap="$2">
            <SizableText ff="$heading" color="$white" size="$8" fow="500">
              ${discountedPrice || price}
            </SizableText>
            {discountedPrice && (
              <SizableText
                ff="$heading"
                color="$secondary5"
                textDecorationLine="line-through"
                size="$8"
                fow="300"
              >
                ${price}
              </SizableText>
            )}
          </XStack>
          {title && (
            <SizableText
              ff="$heading"
              textTransform="uppercase"
              tag="h3"
              size="$6"
              color="$white"
            >
              {title}
            </SizableText>
          )}
          {description && (
            <SizableText tag="p" size="$3" color="$white">
              {description}
            </SizableText>
          )}
        </LinkCore>
        <ButtonLink mt="auto" href={href} theme="secondary8">
          {ctaLabel}
        </ButtonLink>
      </YStack>
    </YStack>
  )
}
