import { Latest } from 'app/blocks/homepage/latest'
import ContentGrid from 'app/components/content/ContentGrid'
import React from 'react'

export const LatestBlock: React.FC<{ block: Latest }> = ({ block }) => {
  const { latestTitle, content } = block || {}

  if (!content || content?.length === 0) {
    return null
  }

  return (
    <ContentGrid
      title={latestTitle || "The Latest"}
      items={content || []}
      href="/latest"
    />
  )
}
