import { Recommended } from 'app/blocks/homepage/recommended'
import FeaturedContentCarousel from 'app/components/content/FeaturedContentCarousel'
import React from 'react'

export const RecommendedBlock: React.FC<{ block: Recommended }> = ({
  block,
}) => {
  const { recommendedTitle, content } = block || {}

  if (!content || content?.length === 0) {
    return null
  }

  return (
    <FeaturedContentCarousel
      items={content || []}
      centered
      title={recommendedTitle || "We think you'll like"}
      titleHighlight={block.collection.title}
      pagination={false}
    />
  )
}
