import { SizableText, Stack, XStack, XStackProps, styled } from '@mythical/ui'
import { IconDrag } from 'app/components/primitive/Icon'
import React from 'react'

const Answer = styled(Stack, {
  fg: 1,
  fs: 1,
  position: 'relative',
  p: '$2.5',
  br: '$3',
  borderWidth: 3,
  boc: '$transparent',
  $gtXxs: {
    p: '$3',
  },
  $gtXs: {
    p: '$3.5',
  },
  variants: {
    selectionType: {
      drag: {
        bg: '$secondary8',
      },
      select: {
        bg: '$secondary8',
      },
    },
    answerSelected: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0.5,
      },
    },
    isSelected: {
      true: {
        boc: '$primary10',
      },
      false: {
        boc: '$transparent',
      },
    },
    isCorrect: {
      true: {
        boc: '$green3',
      },
      false: {
        boc: '$red7',
      },
    },
  } as const,
})

interface QnAAnswerProps {
  type?: 'drag' | 'select'
  order?: number | string
  answer: string
  isCorrect?: boolean | undefined
  isSelected?: boolean | undefined
  answerSelected?: boolean | undefined
  percent?: number
}

export const QnAAnswer = ({
  type,
  order,
  answer,
  isSelected,
  answerSelected,
  isCorrect,
  percent,
  ...props
}: QnAAnswerProps & XStackProps) => {
  return (
    <XStack jc="center" ai="center" gap="$3.5" w="100%" {...props}>
      {order && (
        <SizableText
          minWidth={16}
          ff="$heading"
          color="$primary10"
          size="$8"
          fontWeight="500"
          tt="uppercase"
        >
          {order}
        </SizableText>
      )}
      <Answer
        isCorrect={isCorrect}
        isSelected={isSelected}
        answerSelected={answerSelected}
        selectionType={type}
      >
        {!!percent && (
          <Stack
            pos="absolute"
            top={0}
            left={0}
            bottom={0}
            right={0}
            bg="$secondary7"
            width={`${percent}%`}
            br="$2"
          />
        )}
        <SizableText pos="relative" size="$4" color="$primary1" zIndex="$1">
          {answer}
        </SizableText>
      </Answer>
      {type === 'drag' && (
        <IconDrag width={11} height={25} color="$primary10" />
      )}
    </XStack>
  )
}
