import { SizableText } from '@mythical/ui'
import Spinner from 'app/components/primitive/Spinner'
import Section from 'app/components/structure/Section'
import React from 'react'

import ContentCarousel from './UpcomingContentCarousel'
import { ContentCardUpcomingProps } from '../ContentCardUpcoming'

interface UpcomingContentCarouselProps {
  items: ContentCardUpcomingProps[]
  title: string
  ctaHref?: string
  ctaLabel?: string
  loading?: boolean
}

const UpcomingContentCarousel = ({
  title,
  ctaHref,
  ctaLabel,
  items,
  loading,
}: UpcomingContentCarouselProps) => {
  return (
    <Section padded title={title} ctaHref={ctaHref} ctaLabel={ctaLabel}>
      {loading && <Spinner p="$4" size="large" />}
      {items?.length > 0 && <ContentCarousel items={items} />}
      {!loading && items?.length === 0 && (
        <SizableText textTransform="uppercase" color="$secondary5">
          No upcoming content found
        </SizableText>
      )}
    </Section>
  )
}

export default UpcomingContentCarousel
