import { H2, Paragraph, YStack } from '@mythical/ui'
import { LandingSingleSeries } from 'app/blocks/landing/landing-single-series'
import RelatedContentCarousel from 'app/components/content/RelatedContent/RelatedContentCarousel'
import Section from 'app/components/structure/Section'
import { mapToContentCard } from 'app/queries/content/all-content-list'
import React from 'react'

export const LandingSingleSeriesBlock: React.FC<{ block: LandingSingleSeries }> = ({
  block,
}) => {
  const { content, seriesTitle, seriesSubtitle, hideInfo } = block || {}

  if (!content || content?.length === 0) {
    return null
  }

  const formattedContent = content.map(mapToContentCard)

  return (
    <Section bg="$secondary9" gap="$3" paddedY paddedX>
      <YStack jc="center" ai="center" gap="$2">
        {seriesTitle && (
          <H2
            textAlign="center"
            color="$primary10"
            textTransform="uppercase"
            size="$8"
            fontWeight="500"
            $gtSm={{
              size: '$10',
              fontWeight: '500',
            }}
          >
            {seriesTitle}
          </H2>
        )}
        {seriesSubtitle && (
          <Paragraph
            size="$3"
            textAlign="center"
            maxWidth={960}
            mx="auto"
            $gtXs={{
              size: '$6',
            }}
            color="$primary10"
          >
            {seriesSubtitle}
          </Paragraph>
        )}
      </YStack>

      <RelatedContentCarousel items={formattedContent} hideInfo={hideInfo} />
    </Section>
  )
}
