import { Stack, YStack } from '@mythical/ui'
import React from 'react'

import Section from '../structure/Section'
import { ProductCard } from '../primitive/ProductCard'
import { ButtonLink } from '../primitive/Button'
import { ProductGrid as ProductGridType } from 'app/blocks/product-grid'

interface ProductGridProps {
  block: ProductGridType
}

export const ProductGridBlock: React.FC<ProductGridProps> = ({ block }) => {
  const { mainTitle, titleHighlight, ctaLabel, ctaHref, products } = block

  const hasOneItem = products.length === 1

  return (
    <Section
      padded
      title={mainTitle}
      titleHighlight={titleHighlight}
      {...(ctaHref && {
        customCta: (
          <ButtonLink theme="secondary9Alt" href={ctaHref}>
            {ctaLabel}
          </ButtonLink>
        ),
      })}
    >
      <YStack
        jc="center"
        m="$-2"
        flexWrap="wrap"
        $gtXxs={{ flexDirection: 'row' }}
      >
        {products.map((product) => (
          <Stack
            key={product.id}
            w="100%"
            flex={1}
            p="$2"
            $gtXxs={{ w: '50%', maxWidth: hasOneItem ? 500 : 'auto' }}
          >
            <ProductCard
              key={product.id}
              id={product.id}
              title={product.title}
              image={product.image.url}
              price={product.price}
              discount={product.discount}
              description={product.description}
              ctaLabel={product.ctaLabel}
              href={product.href}
            />
          </Stack>
        ))}
      </YStack>
    </Section>
  )
}
