import { AllSeries } from 'app/blocks/homepage/all-series'
import CollectionCardGrid from 'app/components/collection/CollectionGrid/CollectionCardGrid'
import Section from 'app/components/structure/Section'
import React from 'react'

export const AllSeriesBlock: React.FC<{ block: AllSeries }> = ({ block }) => {
  const { title, collections } = block || {}

  if (!collections || collections?.length === 0) {
    return null
  }

  const formattedCollections = collections.map((collection) => ({
    ...collection,
    title: collection?.name || '',
    href: `/collection/${collection.slug}`,
    image: collection?.thumbnail?.sizes?.thumbnail?.url || '',
    type: 'collection',
  }))

  return (
    <Section
      title={title}
      ctaLabel="View All"
      ctaHref="/content"
      bg="$secondary9"
      paddedY
      paddedX
    >
      <CollectionCardGrid items={formattedCollections} carouselMobile />
    </Section>
  )
}
