import React from 'react'
import { StackProps } from '@mythical/ui'
import { Stack } from '@mythical/ui'

type QnATransitionProps = {
  noExitAnimation?: boolean
}

export const QnATransitionSlide = ({
  children,
  noExitAnimation,
  ...props
}: QnATransitionProps & StackProps) => {
  return (
    <Stack
      pos="relative"
      w="100%"
      bg="$secondary9"
      animation={[
        'page-slide',
        {
          opacity: {
            overshootClamping: true,
          },
          x: {
            overshootClamping: true,
          },
        },
      ]}
      enterStyle={{
        opacity: 0.5,
        scale: 1,
        x: 500,
      }}
      {...(!noExitAnimation
        ? {
            exitStyle: { position: 'absolute', opacity: 0, x: -500 },
          }
        : { exitStyle: { position: 'absolute' } })}
      {...props}
    >
      {children}
    </Stack>
  )
}

export const QnATransitionZoom = ({ children, ...props }: StackProps) => {
  return (
    <Stack
      pos="relative"
      w="100%"
      bg="$secondary9"
      animation={[
        'normal',
        {
          opacity: {
            overshootClamping: true,
          },
        },
      ]}
      enterStyle={{
        opacity: 0.5,
        scale: 1.2,
        x: 0,
      }}
      exitStyle={{
        position: 'absolute',
        opacity: 0,
        x: 0,
        scale: 0.3,
      }}
      {...props}
    >
      {children}
    </Stack>
  )
}
