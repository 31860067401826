import { Stack, styled, YStack } from '@mythical/ui'
import { LinearGradient } from '@tamagui/linear-gradient'
import { useSwiperControls } from 'app/hooks/use-swiper-controls'
import React from 'react'
import { Link } from 'solito/link'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Tag } from '../Tag/Tag'
import { Button } from '../Button'
import { IconChevronLeft, IconChevronRight } from '../Icon'

export interface ITagCarouselProps {
  items: {
    label: string
    href: string
  }[]
}

const CarouselWrapper = styled(Stack, {
  p: '$3',
  pr: "$0",
  $gtLg: {
    px: '$6',
    py: '$5',
  },
  bg: '$secondary9',
  borderTopWidth: 1,
  borderBottomWidth: 1,
  borderColor: '$secondary8',
  w: '100%',
})

const TagCarousel = ({ items }: ITagCarouselProps) => {
  const { swiperProps, hasPrev, hasNext, handleNext, handlePrev } =
    useSwiperControls()

  if (items?.length === 0) return null
  return (
    <CarouselWrapper>
      <Swiper
        style={{ width: '100%' }}
        mousewheel
        slidesPerView="auto"
        slidesPerGroupAuto
        slidesPerGroup={3}
        spaceBetween={8}
        {...swiperProps}
      >
        {items.map((item) => (
          <SwiperSlide
            key={item.label}
            style={{
              display: 'flex',
              flexShrink: 1,
              flexGrow: 0,
              flexBasis: 0,
            }}
          >
            <Link style={{ display: 'block' }} href={item.href}>
              <Tag
                variant="primary"
                displaySize="lg"
                textProps={{ whiteSpace: 'nowrap' }}
                uppercase={false}
              >
                {item.label}
              </Tag>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      <YStack
        zIndex={1}
        position="absolute"
        top="0"
        l="$0"
        h="100%"
        animation="quick"
        opacity={hasPrev ? 1 : 0}
        x={hasPrev ? 0 : -100}
        $xs={{
          display: 'none',
        }}
      >
        <LinearGradient
          position="absolute"
          className="pointer-events-none"
          l="$-3"
          w="$8"
          $gtXs={{
            w: '$12',
          }}
          h="100%"
          zIndex={1}
          start={[1, 1]}
          end={[1, 1]}
          locations={[0.3, 1]}
          colors={['#2a3314', 'transparent']}
        />
        <YStack l="$2" zIndex={1} height="100%" ai="center" jc="center">
          <Button
            p="$0"
            h="$3"
            w="$2"
            theme="tertiary"
            accessibilityLabel="Previous"
            tabIndex={hasPrev ? 0 : -1}
            icon={
              <IconChevronLeft size={32} color="$primary6" />
            }
            onPress={handlePrev}
          />
        </YStack>
      </YStack>

      <YStack
        top="0"
        zIndex={1}
        position="absolute"
        r="$0"
        h="100%"
        x={hasNext ? 0 : 100}
        opacity={hasNext ? 1 : 0}
        animation="quick"
        $xs={{
          display: 'none',
        }}
      >
        <LinearGradient
          position="absolute"
          className="pointer-events-none"
          r="$-3"
          h="100%"
          w="$8"
          start={[1, 1]}
          end={[1, 1]}
          locations={[0, 0.5]}
          colors={['transparent', '#2a3314']}
        />
        <YStack r="$2" height="100%" ai="center" jc="center">
          <Button
            p="$0"
            h="$3"
            w="$2"
            theme="tertiary"
            accessibilityLabel="Next"
            tabIndex={hasNext ? 0 : -1}
            icon={
              <IconChevronRight size={32} color="$primary6" />
            }
            onPress={handleNext}
          />
        </YStack>
      </YStack>
    </CarouselWrapper>
  )
}

export default TagCarousel
