import { CollectableRedemptionForm as CollectableRedemptionFormType } from 'app/blocks/collectable-redemption-form'
import React from 'react'

interface CollectableRedemptionFormBlockProps {
  block: CollectableRedemptionFormType
}

export const CollectableRedemptionFormBlock: React.FC<
  CollectableRedemptionFormBlockProps
> = ({ block }) => (
  <div
    className="redemption-form"
    dangerouslySetInnerHTML={{ __html: block.html }}
  />
)
