import { SizableText } from '@mythical/ui'
import Spinner from 'app/components/primitive/Spinner'
import Section from 'app/components/structure/Section'
import React from 'react'

import { ContentCardProps } from '../ContentCard'
import ContentCarousel from './FeaturedContentCarousel'

interface FeaturedContentCarouselProps {
  items: ContentCardProps[]
  title?: string
  titleHighlight?: string
  ctaHref?: string
  ctaLabel?: string
  centered?: boolean
  loading?: boolean
  showPolls?: boolean
  pagination?: boolean
}

const FeaturedContentCarousel = ({
  title,
  titleHighlight,
  ctaHref,
  ctaLabel,
  items,
  centered,
  loading,
  showPolls,
  pagination = true,
}: FeaturedContentCarouselProps) => {
  return (
    <Section
      padded
      centered={centered}
      title={title}
      titleHighlight={titleHighlight}
      ctaHref={ctaHref}
      ctaLabel={ctaLabel}
    >
      {loading && <Spinner p="$4" size="large" />}
      {items?.length > 0 && (
        <ContentCarousel items={items} showPolls={showPolls} pagination={pagination} />
      )}
      {!loading && items?.length === 0 && (
        <SizableText textTransform="uppercase" color="$secondary5">
          No content found
        </SizableText>
      )}
    </Section>
  )
}

export default FeaturedContentCarousel
