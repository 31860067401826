import { Container, H2, Paragraph } from '@mythical/ui'
import { LandingCollectibles as LandingCollectiblesBlockType } from 'app/blocks/landing/landing-collectibles'
import React from 'react'

import LandingCollectibles from '../../primitive/QuarterlyCollectibles'
import Section from '../../structure/Section'
interface Props {
  block: LandingCollectiblesBlockType
}

export const LandingCollectiblesBlock: React.FC<Props> = ({ block }) => {
  const { collectibles, mainTitle, mainSubtitle } = block

  if (!collectibles || collectibles.length === 0) return null

  const formattedCollectibles = collectibles?.map((item) => ({
    id: item.id,
    title: item.title,
    image: item.thumbnail?.sizes?.square?.url || item.thumbnail.url,
    quarter: item.quarter || '',
    href: item.slug ? `/collectible/${item.slug}` : '',
    hasContent: item.hasContent,
  }))

  return (
    <Section paddedY paddedX>
      {mainTitle && (
        <Container gap="$2" px="$2" pb="$1" $gtMd={{ pb: '$2' }}>
          <Section paddedX>
            <H2
              textAlign="center"
              color="$primary10"
              textTransform="uppercase"
              size="$8"
              fontWeight="700"
              $gtSm={{
                size: '$10',
                fontWeight: '700',
              }}
            >
              {mainTitle}
            </H2>
            {mainSubtitle && (
              <Paragraph
                size="$3"
                textAlign="center"
                $gtXs={{
                  size: '$6',
                }}
                color="$primary10"
              >
                {mainSubtitle}
              </Paragraph>
            )}
          </Section>
        </Container>
      )}
      <LandingCollectibles items={formattedCollectibles} />
    </Section>
  )
}
