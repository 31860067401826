import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { QnAQuestionRankingAnswerType } from '../../QnACard.container'
import { QnAAnswer } from '../../QnAAnswer'

type AnswerWithOrder = QnAQuestionRankingAnswerType & { order: number }

const DraggableQnAAnswer = ({ item, index }) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <div
          className={[
            'DraggableQnAAnswer',
            snapshot.isDragging ? 'dragging' : '',
          ].join(' ')}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <QnAAnswer type="drag" order={item.order} answer={item.answer} />
        </div>
      )}
    </Draggable>
  )
}

export interface QnAQuestionRankingListProps {
  answers: AnswerWithOrder[]
  setAnswers: (data: AnswerWithOrder[]) => void
}

export const QnAQuestionRankingList = ({
  answers,
  setAnswers,
}: QnAQuestionRankingListProps) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const newResult = Array.from(answers)
    const [removed] = newResult.splice(result.source.index, 1)
    newResult.splice(result.destination.index, 0, removed as AnswerWithOrder)

    setAnswers(newResult)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <style jsx global>{`
        .DraggableQnAAnswer {
          top: auto !important;
          left: auto !important;
          padding-bottom: 7px;

          &.dragging {
            opacity: 0.9;
          }
        }
      `}</style>
      <Droppable droppableId="list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {answers.map((item, index) => (
              <DraggableQnAAnswer item={item} index={index} key={item.id} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
