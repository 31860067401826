import { Popular } from 'app/blocks/homepage/popular'
import PopularContentGrid from 'app/components/content/PopularContentGrid'
import React from 'react'

export const PopularBlock: React.FC<{ block: Popular }> = ({
  block,
}) => {
  const { popularTitle, content } = block || {}

  if (!content || content?.length === 0) {
    return null
  }

  return (
    <PopularContentGrid
      title={popularTitle || "Popular this month"}
      items={content || []}
    />
  )
}
