import { YStack } from '@mythical/ui'
import React from 'react'

import { SpotifyEmbedProps } from './SpotifyEmbedProps'

const SpotifyEmbed = ({
  spotifyId,
  mediaType = 'episode',
}: SpotifyEmbedProps) => {
  return (
    <YStack pos="relative" h={200} mt={-46} w="100%" overflow="hidden">
      <YStack pos="absolute" top={46} w="100%">
        <iframe
          src={`https://open.spotify.com/embed/${mediaType}/${spotifyId}`}
          width="100%"
          height="200"
          allowFullScreen
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
          style={{
            border: '0',
          }}
        />
      </YStack>
    </YStack>
  )
}

export default SpotifyEmbed
