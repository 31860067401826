import { ColorTokens, SizableText, Stack, getTokens } from '@mythical/ui'
import React, { useEffect, useState } from 'react'

interface QnATimerProps {
  id: string // Change id to reset the timer
  duration?: number // in seconds
  size?: number
  strokeWidth?: number
  fontSize?: number
  color?: ColorTokens | string
  backgroundColor?: ColorTokens | string
  onComplete?: () => void
}

const QnATimer = ({
  id,
  duration = 10,
  size = 42,
  strokeWidth = 4,
  fontSize = 24,
  color = '$primary10',
  backgroundColor = '$secondary8',
  onComplete,
}: QnATimerProps) => {
  const [timeLeft, setTimeLeft] = useState(duration)

  const center = size / 2
  const radius = (size - strokeWidth) / 2
  const circumference = 2 * Math.PI * radius

  const tokens = getTokens({ prefixed: true })
  const colorValue = tokens.color[color]?.val || color
  const bgColorValue = tokens.color[backgroundColor]?.val || backgroundColor

  const strokeDashoffset = circumference * (timeLeft / duration)

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          onComplete?.()
          return 0
        }
        return prevTime - 1
      })
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [id, duration])

  return (
    <Stack jc="center" ai="center" pos="relative" width={size} height={size}>
      <style jsx global>{`
        .QnATimer {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          transform: rotateZ(270deg);
        }

        .QnATimer__outer {
          transition: stroke-dashoffset 0.5s;
        }
      `}</style>
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        className="QnATimer"
      >
        <circle
          cx={center}
          cy={center}
          r={radius}
          stroke="none"
          fill={bgColorValue}
        />
        <circle
          className="QnATimer__outer"
          fill="none"
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          stroke={colorValue}
          strokeDasharray={`${circumference}, ${circumference}`}
          strokeDashoffset={strokeDashoffset || 0}
        />
      </svg>
      <SizableText
        color="$white"
        ff="$heading"
        fontSize={fontSize}
        lineHeight={1}
        fow="500"
        zIndex="$1"
      >
        {timeLeft}
      </SizableText>
    </Stack>
  )
}

export default QnATimer
