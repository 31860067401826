import { Stack, YStack } from '@mythical/ui'
import Section from 'app/components/structure/Section'
import React from 'react'

import ContentCardXL, { ContentCardXLProps } from '../ContentCardXL'

type PopularContentGridProps = {
  title: string
  items: ContentCardXLProps[]
}

const PopularContentGrid = ({ title, items }: PopularContentGridProps) => {
  return (
    <Section padded title={title}>
      <YStack
        flex={1}
        m="$-2.5"
        $gtXs={{
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {items.map((item) => (
          <Stack p="$2.5" $gtXs={{ w: '50%' }} key={item.id}>
            <ContentCardXL {...item} />
          </Stack>
        ))}
      </YStack>
    </Section>
  )
}

export default PopularContentGrid
