import React from 'react'
import { Container } from '@mythical/ui'
import { PollCard } from '../content/PollCard'
import { Poll } from 'app/blocks/poll'

interface Props {
  block: Poll
}

export const PollBlock: React.FC<Props> = ({ block }) => {
  const { poll } = block
  return (
    <Container smaller>
      <PollCard
        id={poll.id}
        questionId={poll.questions[0]?.id || ''}
        question={poll.questions[0]?.question || ''}
        answers={poll.questions[0]?.answers || []}
        closingDate={poll?.closingDate || ''}
        displaySize="lg"
      />
    </Container>
  )
}
