import { Field } from 'app/blocks/form'

export function buildInitialFormState(fields: Field[]) {
  return fields.reduce((initialSchema, field) => {
    if (field.__typename === 'Checkbox') {
      return {
        ...initialSchema,
        [field.name]: false,
      }
    }

    if (field.__typename === 'Message') return initialSchema

    return {
      ...initialSchema,
      [field.name]: '',
    }
  }, {})
}
