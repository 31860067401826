import { gql } from 'graphql-request'

export const createContentTabsQuery = () => gql`
  {
    contentTabs {
      name
      slug
      type
    }
  }
`

export const mapToContentTabs = (data: any) => {
  return data.contentTabs.map((tab: any) => ({
    label: tab.name,
    href: `/${tab.type}/${tab.slug}`,
  }))
}
