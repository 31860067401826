import { ComingUp } from 'app/blocks/homepage/coming-up'
import { ContentCardUpcomingProps } from 'app/components/content/ContentCardUpcoming'
import UpcomingContentCarousel from 'app/components/content/UpcomingContentCarousel'
import React from 'react'

export const ComingUpBlock: React.FC<{ block: ComingUp }> = ({ block }) => {
  const { comingUpTitle, comingUp } = block || {}

  if (!comingUp || comingUp?.length === 0) {
    return null
  }

  const processed = comingUp.map((item): ContentCardUpcomingProps => {
    return {
      ...item,
      id: item.id,
      title: item.name,
      dateReleaseStart: item.startDate,
      dateReleaseEnd: item.endDate,
      contentTier: item.degree,
      image: item.thumbnail.sizes?.thumbnail?.url,
    }
  })

  return <UpcomingContentCarousel
    title={comingUpTitle || 'Coming up on the society'}
    items={processed || []}
  />
}
