import React from 'react'
import ImageCarousel from 'app/components/primitive/ImageCarousel'
import { IImage, ImageCarousel as IImageCarousel } from 'app/blocks/image-carousel'

export type ImageCarouselBlockProps = {
  block: IImageCarousel
}

export const ImageCarouselBlock = ({ block }: ImageCarouselBlockProps) => {
  const hasImages = block?.images?.length > 0

  if (!hasImages) return null

  const images = block.images.map((image: IImage, i) => ({
    image: image.url,
    thumbnail: image.url,
    alt: image.filename || `Image ${i}`,
  }))

  return <ImageCarousel images={images} />
}
