import { Stack, XStack } from '@mythical/ui'
import { LinearGradient } from '@tamagui/linear-gradient'
import React, { useState } from 'react'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'

import Collectable, { CollectibleProps } from './CollectibleCard'

export type CollectiblesCarouselProps = {
  items: CollectibleProps[]
}

const CollectiblesCarousel = ({ items }: CollectiblesCarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const sliderRef = React.useRef<SwiperRef>(null)

  return (
    <Stack position="relative" fd="row" ai="flex-start" jc="flex-start">
      <XStack position="absolute" l="$0" w="$8" h="100%" zIndex={10}>
        <LinearGradient
          position="absolute"
          className="pointer-events-none"
          l="$0"
          w="$8"
          h="100%"
          start={[1, 1]}
          end={[1, 1]}
          locations={[0.25, 1]}
          colors={['rgba(30, 40, 7, 0.4)', 'transparent']}
        />
      </XStack>
      <Swiper
        className="collectible-carousel"
        loop
        spaceBetween={32}
        ref={sliderRef}
        centeredSlides
        style={{ width: '100%' }}
        slidesPerView="auto"
        breakpoints={{
          320: {
            slidesPerView: 2,
          },
          420: {
            slidesPerView: 2.5,
          },
          760: {
            slidesPerView: 3.8,
          },
        }}
        onSlideChange={(swiper) => {
          setCurrentIndex(swiper.realIndex)
        }}
      >
        {items?.length > 0 &&
          items.map((item, i) => (
            <SwiperSlide
              key={item.title}
              style={{
                scale: currentIndex === i ? '1.15' : '1',
                transition: '0.25s ease-in-out',
              }}
            >
              <Collectable {...item} maxWidth={300} isActive={currentIndex === i} />
            </SwiperSlide>
          ))}
      </Swiper>
      <XStack position="absolute" r="$0" w="$8" h="100%" zIndex={10}>
        <LinearGradient
          position="absolute"
          className="pointer-events-none"
          w="$8"
          h="100%"
          r="$0"
          start={[1, 1]}
          end={[1, 1]}
          locations={[0, 0.8]}
          colors={['transparent', 'rgba(30, 40, 7, 0.4)']}
        />
      </XStack>
    </Stack>
  )
}

export default CollectiblesCarousel
