import { isWeb, SizableText, YStack, YStackProps } from '@mythical/ui'
import { SrOnly } from 'app/components/primitive/SrOnly'
import React from 'react'
import { LinkCore } from 'solito/link'
import ContentThumbnail from '../ContentThumbnail'
import { XStack } from '@mythical/ui'

export interface ContentCardEpisodeProps {
  id: string
  image: string
  title: string
  episodeNumber: number
  episodePart?: number
  href: string
  isActive?: boolean
  wrapperProps?: YStackProps
  onNavigate?: () => void
}

/**
 * Large episodes card
 */
const ContentCardEpisode = ({
  image,
  title,
  href,
  isActive,
  episodeNumber = 1,
  episodePart,
  wrapperProps,
  onNavigate,
}: ContentCardEpisodeProps) => {
  return (
    <YStack
      jc="flex-start"
      ai="flex-start"
      w="100%"
      br="$3"
      gap="$3"
      overflow="hidden"
      {...wrapperProps}
    >
      <LinkCore
        href={href}
        tabIndex={-1}
        Component={YStack}
        componentProps={{
          width: '100%',
          tag: 'a',
          onPress: onNavigate,
          pressStyle: {
            opacity: 0.7,
          },
        }}
      >
        <ContentThumbnail
          image={image}
          hidePlayButton
          wrapperProps={{
            bw: 2,
            br: '$3',
            borderColor: isActive ? '$white' : '$transparent',
          }}
        />
        {isWeb && <SrOnly>Open ${title}</SrOnly>}
      </LinkCore>

      <YStack gap="$2" w="100%" p={2}>
        <LinkCore
          href={href}
          Component={YStack}
          componentProps={{
            tag: 'a',
            gap: '$1.5',
            pressStyle: { opacity: 0.7 },
            hoverStyle: { opacity: 0.8 },
            onPress: onNavigate,
          }}
        >
          {(episodeNumber || episodePart) && (
            <XStack gap="$1.5">
              {episodeNumber && (
                <SizableText
                  color="$primary7"
                  size="$2"
                  textTransform="uppercase"
                  $gtXs={{ size: '$3' }}
                >
                  EP {episodeNumber}
                </SizableText>
              )}
              {episodePart && (
                <SizableText
                  color="$primary7"
                  size="$2"
                  textTransform="uppercase"
                  $gtXs={{ size: '$3' }}
                >
                  PT {episodePart}
                </SizableText>
              )}
            </XStack>
          )}
          <SizableText
            tag="h3"
            color={isActive ? '$white' : '$primary10'}
            size="$4"
            $gtXs={{
              size: '$7',
              fontWeight: '600',
            }}
          >
            {title}
          </SizableText>
        </LinkCore>
      </YStack>
    </YStack>
  )
}

export default ContentCardEpisode
