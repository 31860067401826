import React, { memo } from 'react'
import { Line, Path } from 'react-native-svg'

import { IconProps } from '../IconProps'
import { IconBase } from '../IconBase'
import { themed } from '../themed'


const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Path d="M9.88 9.88a3 3 0 1 0 4.24 4.24" stroke={`${color}`} />
      <Path
         d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"
        stroke={`${color}`}
      />
            <Path
        d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"
        stroke={`${color}`}
      />
      <Line x1="2" x2="22" y1="2" y2="22" stroke={`${color}`} />
    </IconBase>
  )
}

Icon.displayName = 'IconEyeOff'

export const IconEyeOff = memo<IconProps>(themed(Icon))
