import { useMedia } from '@mythical/ui'
import { Featured } from 'app/blocks/featured'
import FeaturedContentCarousel from 'app/components/content/FeaturedContentCarousel'
import LatestContentHero from 'app/components/content/LatestContentHero'
import React from 'react'
import { ContentCardProps } from 'app/components/content/ContentCard'

interface Props {
  featuredPosts?: ContentCardProps[]
}

export const FeaturedBlock: React.FC<Props> = ({ featuredPosts = [] }) => {
  const media = useMedia()

  if (!featuredPosts || featuredPosts?.length === 0) {
    return null
  }

  if (featuredPosts.length <= 2 && !media.xs) {
    return <LatestContentHero items={featuredPosts} />
  }

  return <FeaturedContentCarousel items={featuredPosts || []} />
}
