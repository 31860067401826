import React from 'react'

import { FieldType } from 'app/blocks/form'
import { Text } from 'app/components/blocks/Form/fields/Text'
import { Select } from 'app/components/blocks/Form/fields/Select'
import { Checkbox } from 'app/components/blocks/Form/fields/Checkbox'
import { Date } from 'app/components/blocks/Form/fields/Date'
import RichText from 'app/components/primitive/RichText'

export const fields: Record<FieldType, any> = {
  Text: Text,
  Textarea: Text,
  Number: Text,
  Email: Text,

  Select: Select,
  Checkbox: Checkbox,
  Date: Date,
  Message: ({ message }) => {
    return <RichText content={message} />
  },
}
