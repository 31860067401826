import { Container, H2, Paragraph, YStack } from '@mythical/ui'
import { PromoCode } from 'app/blocks/promo-code'
import React from 'react'

import Section from '../structure/Section'

interface Props {
  block: PromoCode
}

export const PromoCodeBlock: React.FC<Props> = ({ block }) => {
  const { codeTitle, passwordTitle, password, code } = block

  if (!code) return null
  const hasPassword = passwordTitle && password

  return (
    <Container space="$2" mediumLarge>
      <Section jc="center" gap="$4" $gtSm={{ fd: 'row' }} padded>
        <YStack
          fs={1}
          gap="$2.5"
          jc="center"
          $gtSm={{ fb: hasPassword ? '50%' : '100%' }}
        >
          <H2
            fs={1}
            textAlign="center"
            color="white"
            textTransform="uppercase"
            size="$8"
            fontWeight="700"
            $gtSm={{
              size: '$8',
              fontWeight: '700',
            }}
          >
            {codeTitle}
          </H2>

          <Paragraph
            size="$4"
            textAlign="center"
            $gtXs={{
              size: '$8',
            }}
            px="$2"
            color="$primary10"
            selectable
          >
            {code}
          </Paragraph>
        </YStack>
        {hasPassword && (
          <YStack fs={1} gap="$2.5" jc="center" $gtSm={{ fb: '50%' }}>
            <H2
              fs={1}
              textAlign="center"
              color="white"
              textTransform="uppercase"
              size="$8"
              fontWeight="700"
              $gtSm={{
                size: '$8',
                fontWeight: '700',
              }}
            >
              {passwordTitle}
            </H2>

            <Paragraph
              size="$4"
              textAlign="center"
              $gtXs={{
                size: '$8',
              }}
              px="$2"
              color="$primary10"
              selectable
            >
              {password}
            </Paragraph>
          </YStack>
        )}
      </Section>
    </Container>
  )
}
