import React, { useEffect, useState } from 'react'
import { SizableText } from '@mythical/ui'
import { formateDistanceToNow } from './helpers'

export const QnATimer = ({ date, label }: { date: string; label?: string }) => {
  const [displayedDate, setDisplayedDate] = useState(
    formateDistanceToNow(new Date(date))
  )
  const timerEnded = new Date(date) < new Date()

  useEffect(() => {
    if (timerEnded) return
    const interval = setInterval(() => {
      setDisplayedDate(formateDistanceToNow(new Date(date)))
    }, 1000)

    return () => clearInterval(interval)
  }, [date])

  if (timerEnded) {
    return (
      <SizableText ta="center" size="$4" color="$white">
        Please refresh the page to see the latest content.
      </SizableText>
    )
  }

  return (
    <SizableText ta="center" size="$4" color="$white">
      {`${label || 'Time Left'}: `}
      <SizableText size="$4" color="$primary10">
        {displayedDate}
      </SizableText>
    </SizableText>
  )
}
