import { Container, H2, Paragraph } from '@mythical/ui'
import { LandingAllSeries } from 'app/blocks/landing/landing-all-series'
import CollectionCardGrid from 'app/components/collection/CollectionGrid/CollectionCardGrid'
import Section from 'app/components/structure/Section'
import React from 'react'

export const LandingAllSeriesBlock: React.FC<{ block: LandingAllSeries }> = ({ block }) => {
  const { landingAllSeriesTitle: title, landingAllSeriesSubtitle: subtitle, collections } = block || {}

  if (!collections || collections?.length === 0) {
    return null
  }

  const formattedCollections = collections.map((collection) => ({
    ...collection,
    title: collection?.name || '',
    href: `/collection/${collection.slug}`,
    image: collection?.thumbnail?.sizes?.thumbnail?.url || '',
    type: 'collection',
  }))

  return (
    <Section bg="$secondary9" paddedY paddedX>
      <Container gap="$2">
        <H2
          textAlign="center"
          color="$primary10"
          textTransform="uppercase"
          size="$8"
          fontWeight="700"
          $gtSm={{
            size: '$10',
            fontWeight: '700',
          }}
        >
          {title}
        </H2>
        {subtitle && (
          <Paragraph
            size="$3"
            textAlign="center"
            $gtXs={{
              size: '$6',
            }}
            color="$primary10"
          >
            {subtitle}
          </Paragraph>
        )}
      </Container>
      <CollectionCardGrid items={formattedCollections} carouselMobile />
    </Section>
  )
}
