import { Mutation } from 'app/types/mutation'
import { gql } from 'graphql-request'

export const voteMutation = () => gql`
  mutation vote($id: String!, $answers: [Answer!]!, $season: String) {
    vote(id: $id, answers: $answers, season: $season) {
      success
      reason
    }
  }
`

export const voteResult = (data: any) => {
  return data.vote as Mutation
}