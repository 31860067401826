import React from 'react'
import { Field } from 'app/blocks/form'
import { RhfProps } from 'app/components/primitive/Form/RhfProps'
import { CheckboxRhf } from 'app/components/primitive/Form/Checkbox/CheckboxRhf'

export const Checkbox: React.FC<Field & RhfProps<any>> = ({
  name,
  label,
  control,
  required: requiredFromProps,
}) => {
  return (
    <CheckboxRhf
      id={name}
      name={name}
      label={label}
      control={control}
      required={requiredFromProps}
      theme="outlineSecondary9"
    />
  )
}
