import { SizableText, styled } from '@mythical/ui'
import React from 'react'
import { ButtonLink } from '../primitive/Button'
import Section from '../structure/Section'
import { CallToAction as CallToActionBlock } from 'app/blocks/call-to-action'
import { useStores } from 'app/store'

const CtaHeading = styled(SizableText, {
  name: 'CtaHeading',
  tag: 'h2',
  ff: '$heading',
  color: '$primary10',
  fow: '500',
  tt: 'uppercase',
  ta: 'center',
  variants: {
    displaySize: {
      md: {
        size: '$6',
        $gtSm: {
          size: '$8',
          fow: '500',
        },
      },
      lg: {
        size: '$8',
        $gtSm: {
          size: '$9',
          fow: '500',
        },
      },
    },
  } as const,
  defaultVariants: {
    displaySize: 'md',
  },
})

const buttonSizeMap = {
  md: '$3',
  lg: '$4',
} as const

interface CallToActionProps {
  block: CallToActionBlock
}

export const CallToAction = ({ block }: CallToActionProps) => {
  const { userStore } = useStores()
  const { ctaTitle, ctaHref, ctaLabel, displaySize = 'md', excludedTiers } = block

  if (excludedTiers?.length && excludedTiers?.some(tier => tier.name === userStore?.user?.tier)) {
    return null
  }

  return (
    <Section
      gap="$3.5"
      paddedX
      ai="center"
      jc="center"
      maxWidth={800}
      mx="auto"
      $gtXs={{ gap: '$3.5' }}
    >
      {ctaTitle && <CtaHeading displaySize={displaySize}>{ctaTitle}</CtaHeading>}
      <ButtonLink size={buttonSizeMap[displaySize]} href={ctaHref} mx="auto">
        {ctaLabel}
      </ButtonLink>
    </Section>
  )
}
