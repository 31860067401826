import { H2, isWeb, XStack, YStack } from '@mythical/ui'
import Section from 'app/components/structure/Section'
import { useStores } from 'app/store'
import { isIOS } from 'app/utils/constants'
import React from 'react'

import { ButtonLink } from '../Button'
import Spinner from '../Spinner'
import { CollectibleProps } from './CollectibleCard'
import CollectiblesCarousel from './CollectiblesCarousel'

type QuarterlyCollectiblesProps = {
  items?: CollectibleProps[] | undefined
  loading?: boolean
}

const QuarterlyCollectibles = ({
  items,
  loading,
}: QuarterlyCollectiblesProps) => {
  const { userStore } = useStores()
  const isEligible = userStore?.user?.tier !== '3rd Degree'
  if (!items || items.length === 0) return null
  return (
    <YStack pb={!isEligible && isWeb ? '$5' : '$0'}>
      <CollectiblesCarousel items={items} />
      {loading && (
        <YStack minHeight={300} jc="center" ai="center" w="100%">
          <Spinner />
        </YStack>
      )}
      {isEligible && !isIOS && !loading && (
        <Section padded gap="$2">
          <H2
            textAlign="center"
            color="$primary10"
            size="$7"
            fontWeight="500"
            textTransform="uppercase"
            pb="$2"
            $gtSm={{
              size: '$8',
              fontWeight: '500',
            }}
          >
            Upgrade to get quarterly collectible items!
          </H2>
          <XStack
            fd="column"
            jc="center"
            ai="center"
            space="$3"
            $gtXs={{
              fd: 'row',
            }}
          >
            <ButtonLink href="/perks/3">3rd degree membership</ButtonLink>
          </XStack>
        </Section>
      )}
    </YStack>
  )
}

export default QuarterlyCollectibles
