import React from 'react'
import { SizableText, Stack, XStack, YStack } from '@mythical/ui'
import Section from 'app/components/structure/Section'
import { LinkCore } from 'solito/link'
import { SolitoImage } from 'solito/image'
import { ButtonLink } from 'app/components/primitive/Button'
import RichText from 'app/components/primitive/RichText'

export interface AdGridProps {
  items: {
    image?: string
    title?: string
    description?: string
    richTextDescription?: React.ReactNode
    href?: string
    ctaLabel?: string
  }[]
}

export const AdGrid = (props: AdGridProps) => {
  const { items } = props
  return (
    <Section padded>
      <XStack flex={1} m="$-3" flexWrap="wrap">
        {items.map((ad) => (
          <YStack
            key={ad.title}
            gap="$3.5"
            p="$3"
            w="100%"
            $gtSm={{ w: '50%' }}
          >
            <LinkCore
              href={ad.href || '#'}
              Component={Stack}
              componentProps={{
                tag: 'a',
                tabIndex: -1,
                w: '100%',
                aspectRatio: 47 / 26,
                overflow: 'hidden',
                br: '$3',
                hoverStyle: { opacity: 0.9 },
                pressStyle: { opacity: 0.85 },
                focusStyle: { opacity: 0.8 },
                ...(ad.href?.startsWith('http') ? { target: '_blank' } : {}),
              }}
            >
              <SolitoImage
                src={ad.image as string}
                fill
                alt=""
                contentFit="cover"
                sizes="(min-width: 800px) 50vw, 500px"
              />
            </LinkCore>
            <XStack gap="$3.5" ai="center">
              <LinkCore
                Component={YStack}
                href={ad.href || '#'}
                componentProps={{
                  tag: 'a',
                  flex: 1,
                  gap: '$2',
                  hoverStyle: { opacity: 0.8 },
                  pressStyle: { opacity: 0.6 },
                  focusStyle: { opacity: 0.7 },
                  ...(ad.href?.startsWith('http') ? { target: '_blank' } : {}),
                }}
              >
                <SizableText
                  ff="$heading"
                  tag="h3"
                  size="$6"
                  fontWeight="500"
                  color="$secondary1"
                  textTransform="uppercase"
                >
                  {ad.title}
                </SizableText>
                {ad.description && (
                  <SizableText size="$2" color="$secondary1">
                    {ad.description}
                  </SizableText>
                )}
                {ad.richTextDescription && (
                  <RichText
                    content={ad.richTextDescription}
                    textProps={{
                      textAlign: 'left',
                      maxWidth: 450,
                      color: '$white',
                      selectable: true,
                    }}
                    color="$secondary10"
                  />
                )}
              </LinkCore>
              {ad.ctaLabel && (
                <ButtonLink
                  tabIndex={-1}
                  href={ad.href || '#'}
                  theme="outline"
                  {...(ad.href?.startsWith('http') ? { target: '_blank' } : {})}
                >
                  {ad.ctaLabel}
                </ButtonLink>
              )}
            </XStack>
          </YStack>
        ))}
      </XStack>
    </Section>
  )
}
