import React from 'react'
import {
  FocusedInput,
  OnDatesChangeProps,
  START_DATE,
  useDatepicker,
} from '@datepicker-react/hooks'
import { useId, useState } from 'react'
import { Input, Theme, useMedia, XGroup, XStack } from '@mythical/ui'
import { DatePickerProvider } from './DatePickerContext'
import { DatePickerProps } from './DatePickerProps'
import { DatePickerPopover } from './DatePickerPopover'
import { getLocaleDate } from './helpers'
import { FormFieldContainer } from '../FormFieldContainer'
import { color } from '@mythical/ui-config/tokens'

export function DatePicker({
  id,
  numberOfMonths,
  isRangePicker,
  onChange,
  labelFunctions,
  startDate = null,
  endDate = null,
  required,
  placeholder,
  error,
  helperText,
  label,
  hideLabel,
  labelProps,
  labelInline,
  fullWidth,
  inputProps,
  buttonProps,
  popoverProps,
  containerProps,
  theme,
}: DatePickerProps) {
  const media = useMedia()
  const [popoverOpen, setPopoverOpen] = useState(false)

  const [state, setState] = useState<OnDatesChangeProps>({
    startDate: startDate,
    endDate: endDate,
    focusedInput: START_DATE,
  })
  const monthsCount: number = isRangePicker
    ? media.xs
      ? 1
      : numberOfMonths ?? 2
    : 1

  const { activeMonths, firstDayOfWeek, ...context } = useDatepicker({
    startDate: state.startDate,
    endDate: state.endDate,
    focusedInput: state.focusedInput as FocusedInput,
    onDatesChange: (data) => {
      if (!data.focusedInput) {
        setState({ ...data, focusedInput: START_DATE })
      } else {
        setState(data)
      }
      if (typeof onChange === 'function') {
        onChange(data)
      }
      if (!isRangePicker) {
        setPopoverOpen(false)
      }
    },
    numberOfMonths: monthsCount,
    ...(!isRangePicker && {
      minBookingDays: 1,
      exactMinBookingDays: true,
    }),
  })

  const getInputValue = isRangePicker
    ? `${getLocaleDate({ date: state.startDate })}${
        state.endDate ? ' - ' + getLocaleDate({ date: state.endDate }) : ''
      }`
    : getLocaleDate({ date: state.startDate })

  const currentInputProps = {
    placeholderTextColor: color.secondary6,
    ...inputProps,
  }

  const currentButtonProps = {
    ...buttonProps,
  }

  return (
    <Theme name={theme}>
      <DatePickerProvider {...context}>
        <FormFieldContainer
          id={id}
          error={error}
          required={required}
          labelProps={labelProps}
          label={label}
          labelInline={labelInline}
          helperText={helperText}
          fullWidth={fullWidth}
          hideLabel={hideLabel}
          {...containerProps}
        >
          <XStack space flexGrow={1} flexShrink={1}>
            <XGroup
              flexGrow={fullWidth ? 1 : undefined}
              flexShrink={1}
              alignItems={'center'}
              flexDirection={'row'}
              br="$3"
            >
              <XGroup.Item>
                <Input
                  theme="outlineSecondary9"
                  width={'100%'}
                  minWidth={isRangePicker ? '$16' : undefined}
                  value={getInputValue}
                  onPressIn={() => setPopoverOpen(true)}
                  borderRightWidth={0}
                  focusStyle={{
                    outlineWidth: 0,
                  }}
                  br="$3"
                  {...currentInputProps}
                  placeholder={placeholder}
                />
              </XGroup.Item>
              <XGroup.Item>
                <DatePickerPopover
                  activeMonths={activeMonths}
                  monthsCount={monthsCount}
                  firstDayOfWeek={firstDayOfWeek}
                  labelFunctions={labelFunctions}
                  buttonProps={currentButtonProps}
                  open={popoverOpen}
                  onOpenChange={setPopoverOpen}
                  {...popoverProps}
                />
              </XGroup.Item>
            </XGroup>
          </XStack>
        </FormFieldContainer>
      </DatePickerProvider>
    </Theme>
  )
}
