import React from 'react'
import { ReactNode } from 'react'
import {
  Checkbox as TCheckbox,
  CheckboxProps as TCheckboxProps,
  CheckedState,
  Label,
  ParagraphProps,
  SizeTokens,
  XStack,
  XStackProps,
  YStack,
  Theme,
} from '@mythical/ui'
import { Check, Minus } from '@tamagui/lucide-icons'

export type CheckboxProps = XStackProps & {
  checkboxProps?: TCheckboxProps
  label?: ReactNode
  onChange?: (state: CheckedState) => void
  value?: boolean
  error?: boolean
  helperText?: ReactNode
  helperTextProps?: ParagraphProps
  size?: SizeTokens
  required?: boolean
}

export function Checkbox({
  id,
  label,
  onChange,
  error,
  helperText,
  size = '$3',
  value,
  required,
  helperTextProps,
  checkboxProps,
  theme = 'outlineSecondary9',
  ...stackProps
}: CheckboxProps) {
  return (
    <Theme name={theme}>
      <YStack jc="center" {...stackProps}>
        <XStack ai="center" jc="flex-start" gap="$2">
          <TCheckbox
            id={id}
            {...checkboxProps}
            defaultChecked={value || checkboxProps?.defaultChecked}
            size={size}
            onCheckedChange={(checked) => {
              if (typeof onChange === 'function') {
                onChange(checked)
              }
            }}
            borderColor={error ? '$red10' : '$borderColor'}
            borderRadius={4}
            borderWidth={1}
          >
            <TCheckbox.Indicator>
              {checkboxProps?.checked === 'indeterminate' ? (
                <Minus />
              ) : (
                <Check />
              )}
            </TCheckbox.Indicator>
          </TCheckbox>
          {label && (
            <Label fontSize="$3" height="auto" color="$color" htmlFor={id}>
              {label}
            </Label>
          )}
        </XStack>
      </YStack>
    </Theme>
  )
}
